import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add.js';
import iconIt from './iconIt.png';
import './composants.css';
import {useNavigate } from "react-router-dom";

export default function Nav(props) {

  const{ compte_id } = props;
    const [activeLink, setActiveLink] = useState('');

    const [compteUser, setCompteUser]= useState([]);
    const [backendErrorMessage, setBackendErrorMessage]= useState([])

    const request = async () => {
      /*await axios.get(`http://127.0.0.1:8000/api/comptes/show/${compte_id}`)
      .then(({data}) => {
          setCompteUser(data);
          console.log(compteUser);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });*/
  
  }
    const navigate = useNavigate();

    const handleLinkClick = (link) => {
        setActiveLink(link);
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };



    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }

    });



    return (
        <div class='divFirst'>
            <nav class="navbar navbar-expand-sm navbar-dark navbar-custom">
            <div class="container-fluid">
              <a class="navbar-brand text-nav-colors" href="javascript:void(0)" onClick={() => handleLinkClick(`dashboard/${compte_id}`)}>BT-Support</a>
              <button class="navbar-toggler" style={{backgroundColor: '#0077ff'}} type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span class="navbar-toggler-icon" style={{backgroundColor: '#0077ff'}}></span>
              </button>
              <div class="collapse navbar-collapse" id="mynavbar">
                <ul class="navbar-nav me-auto">
                <li className={`nav-item`}>
                      <div class="dropdown">
                      <a class="nav-link text-nav-colors font-bold dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Configuration</a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`ConfigurationSysteme/${compte_id}`)}>Du systeme</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`configurationStock/${compte_id}`)}>Du stock</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`configurationFacturation/${compte_id}`)}>De la facturation</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`configurationIntervention/${compte_id}`)}>Des interventions</a></li>
                      </ul>
                      </div>
                </li>
                <li className={`nav-item`}>
                      <div class="dropdown">
                      <a class="nav-link text-nav-colors font-bold dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Stockage</a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`approvisionements/${compte_id}`)}>Gestion des approvisionements</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`stocks/${compte_id}`)}>Gestion du stock</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`sorties/${compte_id}`)}>Gestion des sorties</a></li>
                      </ul>
                      </div>
                </li>
                <li className={`nav-item`}>
                      <div class="dropdown">
                      <a class="nav-link text-nav-colors font-bold dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Interventions</a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`clients/${compte_id}`)}>Gestion des clients</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`tickets/${compte_id}`)}>Gestion des tickets</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`interventions/${compte_id}`)}>Gestion des interventions</a></li>
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`ateliers/${compte_id}`)}>Gestion des Equipements en ateliers</a></li>
                      </ul>
                      </div>
                </li>
                <li className={`nav-item`}>
                      <div class="dropdown">
                      <a class="nav-link text-nav-colors font-bold dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Facturation</a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`proformats/${compte_id}`)}>Gestion des factures</a></li>
                        {/*<li><a class="dropdown-item" role='button' onClick={() => handleLinkClick(`factures/${compte_id}`)}>Gestion des factures</a></li>*/}
                      </ul>
                      </div>
                </li>
                  <li className={`nav-item`}>
                    <a class="nav-link text-nav-colors font-bold" role='button' onClick={() => handleLinkClick(`caisse/${compte_id}`)}>Caisse</a>
                  </li>
                  <li className={`nav-item `}>
                    <a class="nav-link text-nav-colors font-bold" role='button' onClick={() => handleLinkClick(`reporting/${compte_id}`)}>Bibliothèque</a>
                  </li>
                </ul>                
                <Button variant="contained" style={{margin: 20}}  onClick={() => {navigate(`/`)}} >
                    Déconnexion                      
                </Button>
              </div>
            </div>
              </nav>
              <script></script>
    </div>
    );
}