import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../composants/nav.js';
import './dasboard.css';
import { Autocomplete ,CircularProgress,MenuItem,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, InputLabel} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SyncIcon from '@mui/icons-material/Sync';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from './images/B.png';
import B2 from './images/B2.png';
import H from './images/H.png';
import hand from './images/hand.png';
import laptop from './images/laptop.png';
import lock from './images/lock.png';
import T from './images/T.png';
import techni from './images/techni.png';
import wallet from './images/wallet.png';
import Fuse from 'fuse.js';
import { Label } from '@mui/icons-material';



const loadingImages = [
    B,
    T,
    techni,
    laptop,
    lock,
    hand,
    B2,     
    wallet
   // Ajoutez autant d'images que nécessaire
 ];


 var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';



export default function Reporting() {
    const navigate = useNavigate();
    const { user_id } = useParams();
    const [listEquipement, setlistEquipement] = useState([]);
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [agence,setAgence] = useState();
    const [listAgences, setListAgences] = useState([]);   
    const { compte_id } = useParams();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  
    const request = async () => {
      try{
      await axios.get(`${BACKEND_URL}equipement/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setlistEquipement(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
          console.log(error)
      });

      await axios.get(`${BACKEND_URL}agence/${compte_id}`)
        .then(({data}) => {
            setListAgences(data);
            console.log(listAgences);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

    } catch (error) {
      console.log(error)
    }
     
  
  }
  
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  // Divisez la liste en groupes de 15
const groupedPermissions = chunkArray(listEquipement, 4);
const groupedPermissions2 = chunkArray(listEquipement, 1);

  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
  };
  
    useEffect(() => {
      try {
        request();
      } 
      catch (error) 
      {
        console.log(error)
      }      
      const timer = setTimeout(() => {
        // Mettez à jour l'état pour arrêter le chargement après 3 secondes
        setLoading(false);
      }, 3000); // 3000 millisecondes = 3 secondes
    
      // Nettoyez le timer lorsque le composant est démonté
      const interval = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
      }, 300);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    
      return () => {
        clearTimeout(timer);
        clearInterval(interval);
        window.removeEventListener('resize', handleResize);
    };
    }, []);
  
  
    const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: ['numeroSerie', 'nomEquipement'], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listEquipement, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setlistEquipement(results.map((result) => result.item));
 }

};
  
    if(!loading) {
      return (
        
          <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
             <Stack direction={'column'}
                       alignItems="center" // Centrer verticalement
                       justifyContent="center"
                       sx={{margin: 3}}>                    
                    <div style={{marginRight: 'auto'}}><h3>Reporting des équipements</h3></div>
                    
                    <div style={{}}><h5>Choisissez un équipement</h5></div>
                    {windowWidth>700?
                    <Stack
                        direction={'row'}
                        alignItems="center" // Centrer verticalement
                        justifyContent="center" // Centrer horizontalement
                        sx={{ margin: 2 }}
                        spacing={{ xs: 1, sm: 1, md: 4 }}
                      >
                          <TableContainer component={Paper}>
                          <Table sx={{minWidth:850, maxWidth: 1000 }} aria-label="simple table">
                              <TableHead style={{backgroundColor: 'blue',}}>
                                  <TableRow>
                                      <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Equipement</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              <div style={{marginBottom: 10,marginTop: 10, marginRight: 'auto', backgroundColor:'white'}}>
                              <Stack direction={windowWidth>700?'row':'column'}
          spacing={2}
        alignItems='center'
    >
                                  {/*<TextField type="text" 
                                        value={searchQuery} 
                                        onChange={handleSearchChange} 
                                        placeholder="Rechercher...">
                                  </TextField>*/}   
                            <Stack direction={'column'} >   
                              <InputLabel>Selectionner une agence</InputLabel>                                          
                            <Autocomplete
                            
                              id="combo-box-demo"
                              options={listAgences}
                              getOptionLabel={(option) => option.nomAgence}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              //defaultValue={'jsjsjs'}
                              //value={typeP.value}
                              onChange={(event, newValue) => {
                                setAgence(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label={agence}  />
                              )}
                            />
                            </Stack>
                            <div style={{marginBottom: 10, //marginRight: 3
                            }}>
                              <Button variant='contained' onClick={() => {
                                 axios.get(`${BACKEND_URL}agence/${agence}/equipements/${compte_id}`)
                                 .then(({data}) => {
                                   setlistEquipement(data);
                                     console.log(listEquipement);
                                 }).catch ((error) =>
                                 {
                                     setBackendErrorMessage(error.response.data.error);
                                 });
                              }}>Rechercher</Button>
                            </div>
                            <Tooltip title="Reinitialiser les filtres" >
                              <IconButton style={{ color: '#0D9276'}} onClick={()=>{   
                                    request()
                              }} >
                                <SyncIcon />
                              </IconButton>
                            </Tooltip>
                            </Stack>
                                  </div>
                              {groupedPermissions.map((group, groupIndex) => (
                                <TableRow key={groupIndex}>
                                  {group.map((row, index) => (
                                    <TableCell key={index}>                                    
                                    <Stack>
                                    <Tooltip onClick={() => {navigate(`${row.numeroSerie}/equipement/${compte_id}`)}}>
                                      <Container
                                        role='button'
                                        sx={{
                                          boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',
                                          minWidth: 120,
                                          backgroundColor: '#F3F8FF',
                                          height: 100,
                                          borderRadius: 1.5,
                                          display: 'flex'
                                        }}
                                      >
                                        <Stack
                                          direction={'column'}
                                          alignItems="center"
                                          sx={{ margin: 'auto', textAlign: 'center' }}
                                        >                                  
                                          <Stack sx={{ fontWeight: 600, color: '#00000' }}>
                                            {row.nomProd}
                                          </Stack>
                                          <Stack sx={{ fontWeight: 600, color: '#00000' }}>
                                            {` Série : ${row.numeroSerie}`}
                                          </Stack>
                                        </Stack>
                                      </Container>
                                    </Tooltip>
                                  </Stack>
                                  </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                                </TableBody>
                          </Table>
                  </TableContainer>
                          

                      </Stack>
                      :
                      <Stack
                        direction={'column'}
                        alignItems="center" // Centrer verticalement
                        justifyContent="center" // Centrer horizontalement
                        sx={{ margin: 2 }}
                        spacing={{ xs: 1, sm: 1, md: 4 }}
                      >
                          <TableContainer component={Paper}>
                          <Table sx={{minWidth:250, maxWidth: 400 }} aria-label="simple table">
                              <TableHead style={{backgroundColor: 'blue',}}>
                                  <TableRow>
                                      <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Equipement</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              <div style={{marginBottom: 10,marginTop: 10, marginRight: 'auto', backgroundColor:'white'}}>
                                  <TextField type="text" 
                                        value={searchQuery} 
                                        onChange={handleSearchChange} 
                                        placeholder="Rechercher...">
                                  </TextField></div>
                              {groupedPermissions2.map((group, groupIndex) => (
                                <TableRow key={groupIndex}>
                                  {group.map((row, index) => (
                                    <TableCell key={index}>                                    
                                    <Stack>
                                    <Tooltip onClick={() => {navigate(`${row.numeroSerie}/equipement/${compte_id}`)}}>
                                      <Container
                                        role='button'
                                        sx={{
                                          boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',
                                          minWidth: 120,
                                          backgroundColor: '#F3F8FF',
                                          height: 100,
                                          borderRadius: 1.5,
                                          display: 'flex'
                                        }}
                                      >
                                        <Stack
                                          direction={'column'}
                                          alignItems="center"
                                          sx={{ margin: 'auto', textAlign: 'center' }}
                                        >                                  
                                          <Stack sx={{ fontWeight: 600, color: '#00000' }}>
                                            {row.nomProd}
                                          </Stack>
                                          <Stack sx={{ fontWeight: 600, color: '#00000' }}>
                                            {` Série : ${row.numeroSerie}`}
                                          </Stack>
                                        </Stack>
                                      </Container>
                                    </Tooltip>
                                  </Stack>
                                  </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                                </TableBody>
                          </Table>
                  </TableContainer>
                          

                      </Stack>
                            }
              </Stack>           
          </div>
      );
    }
    else
    {
        return(
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh', // 100% de la hauteur de la vue
            }}
          >
            <Stack direction={'column'} alignItems={'center'} spacing={1}>
                <CircularProgress />
                <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
            </Stack>
          </Box>
        );
    }
}