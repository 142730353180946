import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import { Autocomplete ,CircularProgress,MenuItem,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
  var countData=1;



export default function ConfigurationUtilisateur() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listUtilisateur, setListUtilisateur] = useState([]);
    const [listRole, setListRole] = useState([]);
    const [modifUser, setModifUser] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [role, setRole] = useState([]);
    const [nomUtilisateur, setNomUtilisateur] = useState('');
    const [user_id, setUser_id] = useState('');
    const [pays_compte, setPays_compte] = useState('');
    const [mailUtilisateur, setMailUtilisateur] = useState('');
    const [telUtilisateur, setTelUtilisateur] = useState('');
    const [passUtilisateur, setPassUtilisateur] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogModifier, setOpenDialogModifier] = React.useState(false);
    const [openDialogCompte, setOpenDialogCompte] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listUtilisateur.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listUtilisateur.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:2;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}utilisateurs/${compte_id}`)
        .then(({data}) => {
            setListUtilisateur(data);
            console.log(listUtilisateur);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.request.response);
        });

        await axios.get(`${BACKEND_URL}roles/${compte_id}`)
        .then(({data}) => {
            setListRole(data);
            console.log(listRole);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.request.response);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialogModifier = () => {      
      setOpenDialogModifier(true);
    };
  
    const handleCloseDialogModifier = () => {
      setBackendErrorMessage('')      
      setOpenDialogModifier(false);
    };

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };

    const handleClickOpenDialogCompte = () => {
      setOpenDialogCompte(true);
    };
  
    const handleCloseDialogCompte = () => {
      setBackendErrorMessage('')
      setOpenDialogCompte(false);
    };
    
    const ajoutUtilisateur = async (e) => {
      if(nomUtilisateur==null || nomUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un nom')
      }
      else if(mailUtilisateur==null || mailUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un e-mail')
      }
      else if(telUtilisateur==null || telUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un numéro de téléphone')
      }
      else if(passUtilisateur==null || passUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un mot de passe')
      }
      else if(passUtilisateur.length<=8)
      {
        setBackendErrorMessage('Veuillez entrer un mot de passe valide (8 carateres minimum)')
      }
      else {
      await axios.post(`${BACKEND_URL}utilisateurs/create/${compte_id}`, {
                  name: nomUtilisateur,
                  email: mailUtilisateur,
                  tel: telUtilisateur,
                  password: passUtilisateur
                }).then((data) =>{
                  request();
                  console.log(data)
                 handleCloseDialog();
                })
                .catch(error => {
                  console.log('Erreur de requête :', error.request.response);
                  setBackendErrorMessage(error.request.response)
      });
    }
    };

    const ModifierUtilisateur = async (e) => {
      console.log(nomUtilisateur)
      console.log(mailUtilisateur)
      console.log(telUtilisateur)
      console.log(passUtilisateur)
      if(nomUtilisateur==null || nomUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un nom')
      }
      else if(mailUtilisateur==null || mailUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un e-mail')
      }
      else if(telUtilisateur==null || telUtilisateur=='')
      {
        setBackendErrorMessage('Veuillez entrer un numéro de téléphone')
      }
      else if(passUtilisateur==null || passUtilisateur=='')
      {
        await axios.put(`${BACKEND_URL}utilisateurs/update/${user_id}/${compte_id}`, {
                  name: nomUtilisateur,
                  email: mailUtilisateur,
                  tel: telUtilisateur,                  
                }).then((data) =>{
                  request();
                  console.log(data)
                  handleCloseDialogModifier();
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.request.response)
      });
      }
      else if(passUtilisateur.length <=8)
      {
        setBackendErrorMessage('Veuillez entrer un mot de passe valide (9 caratères minimum)')
      }
      else {
      await axios.put(`${BACKEND_URL}utilisateurs/update/${user_id}/${compte_id}`, {
                  name: nomUtilisateur,
                  email: mailUtilisateur,
                  tel: telUtilisateur,
                  password: passUtilisateur
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialogModifier(false);
                  
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.request.response)
      });
    }
    };


    const ajoutCompte = async (e) => {
      if(pays_compte==null || pays_compte=='')
      {
        setBackendErrorMessage('Veuillez sélectionner un pays')
      }
      else if(role==null || role=='')
      {
        setBackendErrorMessage('Veuillez sélectionner un role')
      }
      else {
      await axios.post(`${BACKEND_URL}comptes/create/${compte_id}`, {
                  user_id: user_id,
                  pays: pays_compte,   
                  roleCompte: role               
                }).then((data) => {                  
                  console.log(data)
                   axios.post(`${BACKEND_URL}comptes/${role}/assign_role/${compte_id}`, {                                             
                              }).then((data) =>{                  
                                console.log(data)

                                handleCloseDialogCompte();
                              })
                              .catch(error => {
                                console.error('Erreur de requête :', error);
                                setBackendErrorMessage(error.request.response)
                      });
                  //setOpenDialogCompte(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.request.response)
      });
    }
    };

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }   
      else{
        window.alert('Vous ne pouvez pas le supprimer car il a éffectuer certaines actions sur l\'application (Tracabilité)')
        return;
      } 
        /*try {
          await axios.delete(`${BACKEND_URL}utilisateurs/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert('Vous ne pouvez pas supprimer cet élément car il est utilisé dans une autre entité')
          // Ajoutez ici la gestion des erreurs
        }*/
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
        
        const tableRows = listUtilisateur.map((row, index) => (
          `<tr>
            <td>${index + 1}</td>
            <td>${row.name}</td>
            <td>${row.email}</td>
            <td>${row.tel}</td>
          </tr>`
        )).join('');
        
        // Utilisez la chaîne HTML générée pour construire le document HTML
        const htmlContent = `
          <html lang="fr">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Tableau des utilisateurs</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              <h2>Tableau des rôles</h2>
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Nom</th>
                    <th>E-mail</th>
                    <th>Téléphone</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableRows}
                </tbody>
              </table>
            </body>
          </html>`;
        
        // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 1000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
        
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationSysteme/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #B67352',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des utilisateurs
                  </Stack>
                  <Stack>
                  {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Utilisateurs</TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >E-mail</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.name}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.email}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.tel}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{     
                                                              setUser_id(row.id)  
                                                              setNomUtilisateur(row.name)
                                                              setMailUtilisateur(row.email)
                                                              setTelUtilisateur(row.tel)
                                                              setPassUtilisateur('')   
                                                              handleClickOpenDialogModifier()                                                
                                                          /*axios.get(`${BACKEND_URL}utilisateurs/show/${row.id}`)
                                                          .then(({data}) =>  {
                                                              setModifUser(data);
                                                              
                                                              console.log(data);
                                                              
                                                          }).catch ((error) =>
                                                          {
                                                              setBackendErrorMessage(error.request.response);
                                                          });*/
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Afficher compte'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-comptes/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Creer un compte pour l'utilisateur">
                                                        <IconButton style={{ color: 'blue' }} onClick={()=>{
                                                          handleClickOpenDialogCompte()
                                                          setUser_id(row.id)
                                                           
                                                          }} >
                                                          <PersonAddIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                        </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                                
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Utilisateurs</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#FED8B1'}} colSpan={2}>Nom</TableCell>
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >E-mail</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.email}</TableCell>   
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.tel}</TableCell>                                         
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Action</TableCell>                                            
                                    </TableRow>                                        
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{     
                                                              setUser_id(row.id)  
                                                              setNomUtilisateur(row.name)
                                                              setMailUtilisateur(row.email)
                                                              setTelUtilisateur(row.tel)
                                                              setPassUtilisateur('')   
                                                              handleClickOpenDialogModifier()                                                
                                                          /*axios.get(`${BACKEND_URL}utilisateurs/show/${row.id}`)
                                                          .then(({data}) =>  {
                                                              setModifUser(data);
                                                              
                                                              console.log(data);
                                                              
                                                          }).catch ((error) =>
                                                          {
                                                              setBackendErrorMessage(error.request.response);
                                                          });*/
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Afficher compte'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-comptes/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Creer un compte pour l'utilisateur">
                                                        <IconButton style={{ color: 'blue' }} onClick={()=>{
                                                          handleClickOpenDialogCompte()
                                                          setUser_id(row.id)
                                                           
                                                          }} >
                                                          <PersonAddIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                        </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                     
                                                </TableBody>
                                                 ))}
                                
                            </Table>
                    </TableContainer>
                  }
                    <div class={windowWidth>700?"flex-row":"flex-column"}>
                                    <nav > 
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={prePage}>Préc</a>
                                            </li>
                                            {
                                              getPageNumbersToShow().map((n,i) =>
                                              (
                                                <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                                  <a href='#' className='page-link'
                                                  onClick={() => changeCPage(n)}>{n}</a>
                                                </li>
                                              )
                                              )
                                            }
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={nextPage}>Suiv</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div>
                                      <h5>Total utilisateurs :  {listUtilisateur.length}</h5> 
                                    </div>
                                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'utilisateur"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                           {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Nom utilisateur'
                                value={nomUtilisateur}
                                onChange={(e)=> {
                                    setNomUtilisateur(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='mail' 
                                label='Mail utilisateur'
                                value={mailUtilisateur}
                                onChange={(e)=> {
                                    setMailUtilisateur(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='tel' 
                                label='Téléphone utilisateur'
                                value={telUtilisateur}
                                onChange={(e)=> {
                                    setTelUtilisateur(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='password' 
                                label='Mot de passe utilisateur'
                                value={passUtilisateur}
                                onChange={(e)=> {
                                    setPassUtilisateur(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutUtilisateur()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                           
                    </Stack>


                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>   

                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogModifier}
                  onClose={handleCloseDialogModifier}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Modification de l'utilisateur"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                           {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Nom utilisateur'
                                defaultValue={nomUtilisateur}
                                onChange={(e)=> {
                                    setNomUtilisateur(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='mail' 
                                label='Mail utilisateur'
                                defaultValue={mailUtilisateur}
                                onChange={(e)=> {
                                    setMailUtilisateur(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='tel' 
                                label='Téléphone utilisateur'
                                defaultValue={telUtilisateur}
                                onChange={(e)=> {
                                    setTelUtilisateur(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='password' 
                                label='Mot de passe utilisateur'
                                value={passUtilisateur}
                                onChange={(e)=> {
                                    setPassUtilisateur(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ModifierUtilisateur()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                           
                    </Stack>
                    

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogModifier} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogCompte}
                  onClose={handleCloseDialogCompte}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du compte"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          <Stack
                              direction={'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                             {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <TextField type='text' 
                                label='Pays'
                                value={pays_compte}
                                style={{minWidth:230}}
                                select
                                onChange={(e)=> {
                                    setPays_compte(e.target.value)
                                }}
                            >
                              <MenuItem key={'Burkina'} value={'Burkina'}>
                                {'Burkina'}
                              </MenuItem>
                              {/*<MenuItem key={'Mali'} value={'Mali'}>
                                {'Mali'}
                              </MenuItem>
                              <MenuItem key={'Niger'} value={'Niger'}>
                                {'Niger'}
                              </MenuItem>
                              <MenuItem key={'Togo'} value={'Togo'}>
                                {'Togo'}
                              </MenuItem>
                              <MenuItem key={'Benin'} value={'Benin'}>
                                {'Benin'}
                              </MenuItem>
                              <MenuItem key={'Senegal'} value={'Senegal'}>
                                {'Senegal'}
                              </MenuItem>
                              <MenuItem key={'RCI'} value={'RCI'}>
                                {'RCI'}
                              </MenuItem>
                              <MenuItem key={'Guinee-Bissau'} value={'Guinee-Bissau'}>
                                {'Guinee-Bissau'}
                              </MenuItem>*/}
                              </TextField>  

                              <Autocomplete
                              id="combo-box-demo"
                              options={listRole}
                              getOptionLabel={(option) => option.name}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={role.value}
                              onChange={(event, newValue) => {
                                setRole(newValue ? newValue.name:'');
                                console.log(newValue ? newValue.name:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un role"  />
                              )}
                            />                       
                          </Stack>                          
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutCompte()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogCompte} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>     
        </div>
    );

 
    function nextPage(){
      if(firstIndex+10 < listUtilisateur.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}