import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import ScheduleIcon from '@mui/icons-material/Schedule';
import * as XLSX from 'xlsx'
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EquipementOfAgence from './equipementOfAgence.js';



var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';



export default function HistoriueEquipement() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listHistoriques, setListHistoriques] = useState([]);
    const [equipement, setEquipement] = useState([]);
    const [open, setOpen] = React.useState(false);

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 5;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listHistoriques.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listHistoriques.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;
    
    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const { numeroSerie, agence_id, client_id } = useParams();
    const { compte_id } = useParams();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}equipement/historique/${numeroSerie}/${compte_id}`)
        .then(({data}) => {
            setListHistoriques(data);
            console.log(listHistoriques);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listHistoriques.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.nomAgence}</td> 
                <td>${row.dateInterv}</td> 
                <td>${row.name}</td> 
                <td>${row.tckIntervention}</td> 
                <td>${row.etatApres}</td>                     
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Historiques de l'equipement de serie ${numeroSerie}</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                  </style>
                </head>
                <body>
                  <h2>Historiques de l'equipement de serie ${numeroSerie}</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Agence</th>
                      <th>Date d'intervention</th> 
                      <th>Technicien</th>                      
                      <th>Ticket d'intervention</th>
                      <th>Etat après intervention</th>  
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        const handleExportExcel = () => {
          var wb = XLSX.utils.book_new();
          var ws = XLSX.utils.json_to_sheet(listHistoriques);
      
          //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
      
          // Mettre la première ligne en gras
          //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
      
          XLSX.utils.book_append_sheet(wb, ws , `Historiques équipement ${numeroSerie}`);
      
          XLSX.writeFile(wb , `Historiques équipement ${numeroSerie}.xlsx`);
      
        }
  

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              window.removeEventListener('resize', handleResize);
          };
          }, []);


  const navigate = useNavigate();

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`clients/${compte_id}/${client_id}/agences/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?10:2, 
                      marginRight: windowWidth>700?10:2, 
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #0802A3',
                      backgroundColor:'white'}}>                  
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper} sx={{ marginTop: 5}}>
                            <Table sx={{ minWidth: 800 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={6}>Historiques</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Agence</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date d'intervention</TableCell>  
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Technicien</TableCell>  
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ticket d'intervention</TableCell>  
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État apres intervention</TableCell>                                                                                                                                                                             
                                    </TableRow>
                                    {listHistoriques.length === 0 ? (
                                        <TableRow>
                                          <TableCell colSpan={6} align="center" style={{fontWeight: 600, color: 'red'}}>
                                            Cet équipement n'a pas encore recu de maintenances antérieures.
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{index+1}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomAgence}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.dateInterv}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.name}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.tckIntervention}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell>                                           
                                                    </TableRow>
                                                      )))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper} sx={{ marginTop: 5}}>
                            <Table sx={{ minWidth: 150, maxWidth:300 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={6}>Historiques</TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                {data.length === 0 ? (
                                  <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={6} align="center" style={{fontWeight: 600, color: 'red'}}>
                                            Cet équipement n'a pas encore recu de maintenances antérieures.
                                          </TableCell>
                                        </TableRow>
                                        </TableBody>
                                      ) : (
                                        data.map((row, index) => (
                                          <TableBody>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Agence</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date d'intervention</TableCell>                                              
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.nomAgence}</TableCell>
                                            <TableCell align="center" style={{}}>{row.dateInterv}</TableCell>                                            
                                    </TableRow>
                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Technicien</TableCell>  
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ticket d'intervention</TableCell>                                              
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.name}</TableCell>
                                            <TableCell align="center" style={{}}>{row.tckIntervention}</TableCell>
                                            
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État apres intervention</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell>                                           
                                    </TableRow>
                                                      
                                                </TableBody>
                                              )))}
                            </Table>
                    </TableContainer>
                                                    }
                    <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total : {listHistoriques.length}</h5> 
                        </div>
                </div>
                  </Stack>                 
            </Stack>
            
        </div>
    );
    function nextPage(){
      if( firstIndex+5 <listHistoriques.length)
      {
        setCurrentPage(currentPage + 1);
      }
    }

    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
      }
    }

    function changeCPage(id){
      setCurrentPage(id);
    }
}