import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress,Box, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
  var countData=1;



export default function ConfigurationModeleProd() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listModeleProd, setListModeleProd] = useState([]);
    const [listMarqueProd, setListMarqueProd] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [idMarque, setIdMarque] = useState('');
    const [nomModele, setNomModele] = useState('');
    const [nomMarque, setNomMarque] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogModifier, setOpenDialogModifier] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listModeleProd.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listModeleProd.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}modeleProduit/${compte_id}`)
        .then(({data}) => {
            setListModeleProd(data);
            console.log(listModeleProd);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });


        await axios.get(`${BACKEND_URL}marqueProduit/${compte_id}`)
        .then(({data}) => {
            setListMarqueProd(data);
            console.log(listMarqueProd);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }


    const handleClickOpenDialogModifier = () => {
      setOpenDialogModifier(true);
    };
  
    const handleCloseDialogModifier = () => {
      setBackendErrorMessage('')
      setOpenDialogModifier(false);
    };

    const modifierModele = async (e) => {
      if(idMarque==null || nomModele=='')
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else{
      await axios.post(`${BACKEND_URL}modeleProduit/create/${compte_id}`, {
                    idMarque: idMarque,
                    nomModeleProd: nomModele,
                }).then((data) => {
                  request();
                  console.log(data)
                  setOpenDialog(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
              });
            }
    };

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };
    
    const ajoutModele = async (e) => {
      if(idMarque==null || nomModele=='')
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else{
      await axios.post(`${BACKEND_URL}modeleProduit/create/${compte_id}`, {
                    idMarque: idMarque,
                    nomModeleProd: nomModele,
                }).then((data) => {
                  request();
                  console.log(data)
                  setOpenDialog(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
              });
            }
    };

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}modeleProduit/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
        
          const tableRows = listModeleProd.map((row, index) => (
            `<tr>
              <td>${index + 1}</td>
              <td>${row.nomMarqueProd}</td>
              <td>${row.nomModeleProd}</td>              
            </tr>`
          )).join('');
          
          // Utilisez la chaîne HTML générée pour construire le document HTML
          const htmlContent = `
            <html lang="fr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">    
                <title>Tableau des modèles</title>              
                <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h2>Tableau des modèles</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Numéro</th>
                      <th>Marque produit</th>
                      <th>Modèle produit</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    ${tableRows}
                  </tbody>
                </table>
              </body>
            </html>`;
          
          // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 3000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 300);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
        
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };

          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if(listModeleProd.length>0 && !loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationStock/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => {}} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #FC6736',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des modèle de produits
                  </Stack>
                  <Stack>
                    <TableContainer component={Paper}>
                            <Table sx={{  minWidth: windowWidth-200, }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#FC6736',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={4}>Modèle produit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Marque</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Modèle</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>
                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomMarqueProd}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomModeleProd}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=> {
                                                          handleClickOpenDialogModifier()
                                                          setNomModele(row.nomModeleProd)
                                                          setNomMarque(row.nomMarqueProd)
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    {/*<Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`/afficher-approvisionement/${row.id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                        </Tooltip> */}                         
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    <div class={windowWidth>700?"flex-row":"flex-column"}>
                                    <nav > 
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={prePage}>Préc</a>
                                            </li>
                                            {
                                              getPageNumbersToShow().map((n,i) =>
                                              (
                                                <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                                  <a href='#' className='page-link'
                                                  onClick={() => changeCPage(n)}>{n}</a>
                                                </li>
                                              )
                                              )
                                            }
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={nextPage}>Suiv</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div>
                                      <h5>Total modèle:  {listModeleProd.length}</h5> 
                                    </div>
                                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du modèle"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Autocomplete
                              id="combo-box-demo"
                              options={listMarqueProd}
                              getOptionLabel={(option) => option.nomMarqueProd}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              
                              onChange={(event, newValue) => {
                                setIdMarque(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez une marque"  />
                              )}
                            />
                            <TextField type='text' 
                            label='Entrez le modèle'
                            value={nomModele}
                            onChange={(e)=> {
                                setNomModele(e.target.value)
                            }}
                            ></TextField>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutModele()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>          
                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogModifier}
                  onClose={handleCloseDialogModifier}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Modification du modèle"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Autocomplete
                              id="combo-box-demo"
                              options={listMarqueProd}
                              getOptionLabel={(option) => option.nomMarqueProd}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              defaultValue={nomMarque}
                              onChange={(event, newValue) => {
                                setIdMarque(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez une marque"  />
                              )}
                            />
                            <TextField type='text' 
                            label='Entrez le modèle'
                            value={nomModele}
                            onChange={(e)=> {
                                setNomModele(e.target.value)
                            }}
                            ></TextField>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {modifierModele()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogModifier} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress
              />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }

  
  function nextPage(){
    if(firstIndex+10 < listModeleProd.length)
    {
      setCurrentPage(currentPage + 1);
      countData=countData+10;
    }
  }

  function prePage(){
    if(firstIndex-1>0)
    {
      setCurrentPage(currentPage - 1);
      countData=countData-10;
      console.log(countData)
    }
  }

  function changeCPage(id){
    setCurrentPage(id);
    countData = ((id*10)-9)
  }
}