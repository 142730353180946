import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import HistoryIcon from '@mui/icons-material/History';
import ScheduleIcon from '@mui/icons-material/Schedule';
import * as XLSX from 'xlsx'
import { format } from 'date-fns';
import axios from 'axios';
import Fuse from 'fuse.js';
import m2agroup from '../images/m2a.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
var countData=1;

export default function Atelier() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listEquipement, setListEquipement] = useState([]);
    const [listClient, setListClient] = useState([]);
    const [listUtilisateurs, setListUtilisateurs] = useState([]);
    const [listProduit, setListProduit] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [equi_id, setEqui_id] = useState('');
    const [nomEquipement, setnomEquipement] = useState('');
    const [nomClient, setNomClient] = useState('');
    const [reference, setReference] = useState('');
    const [dateEntre, setDateEntre] = useState('');
    const [operateurEntre, setOperateurEntre] = useState('');
    const [operateurReception, setOperateurReception] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [panne, setPanne] = useState('');
    const [etat, setEtat] = useState('');
    const [diagnostic, setDiagnostic] = useState('');
    const [statutEquipement, setstatutEquipement] = useState('');
    const clientOptions = [...listClient, { id: -1, nomClient: "Tiers" }];

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listEquipement.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listEquipement.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700? 5 : 3;
    
    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const { client_id } = useParams();
    const { compte_id } = useParams();


    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogSortie, setOpenDialogSortie] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}equipementAtelier/${compte_id}`)
        .then(({data}) => {
            setListEquipement(data);
            console.log(listEquipement);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}client/${compte_id}`)
        .then(({data}) => {
            setListClient(data);
            console.log(listClient);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}utilisateurs/${compte_id}`)
        .then(({data}) => {
            setListUtilisateurs(data);
            console.log(listUtilisateurs);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}produit/showEquipement/${compte_id}`)
        .then(({data}) => {
            setListProduit(data);
            console.log(listProduit);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);

    };


    const handleClickOpenDialogSortie = () => {

        setOpenDialogSortie(true);
      };
    
      const handleCloseDialogSortie = () => {
        setBackendErrorMessage('')
        setOpenDialogSortie(false);
      };
    

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

    const ajoutEquipement = async () => {
      if(nomEquipement=='')
      {
        setBackendErrorMessage('Veuillez entrez le nom de l\'équipement')
      }
      else if (nomClient=='')
      {
        setBackendErrorMessage('Veuillez entrez le nom du client')
      }
      else if (operateurEntre=='')
      {
        setBackendErrorMessage('Veuillez entrez le nom de l\'opérateur d\'entrée ')
      }
      else if (operateurReception=='')
      {
        setBackendErrorMessage('Veuillez entrez le nom de l\'opérateur de reception ')
      }
      else if (dateEntre=='')
      {
        setBackendErrorMessage('Veuillez entrez la date d\'entrée ')
      }
      else if (reference=='')
      {
        setBackendErrorMessage('Veuillez entrez la référence de l\'équipement')
      }
      else {
        await axios.post(`${BACKEND_URL}equipementAtelier/create/${compte_id}`, {                    
                    nomClientAtelier : nomClient ,
                    nomEquipementAtelier : nomEquipement,
                    reference: reference,
                    dateEntre: dateEntre,
                    operateurEntre: operateurEntre,
                    operateurReception: operateurReception,
                    commentaire : commentaire
        } ).then((data) =>{
            request();
            console.log(data)
           handleCloseDialog()
          })
          .catch(error => {
            console.error('Erreur de requête :', error);
            setBackendErrorMessage(error.response.data.error)
});
      }
    }


    const modifierStatutEquipement = async () => {
      if(panne=='')
      {
        setBackendErrorMessage('Veuillez entrez l\'origine de la panne')
      }
      else if (etat=='')
      {
        setBackendErrorMessage('Veuillez entrez l\'état actuel de l\'équipement')
      }
      else{
                  await axios.put(`${BACKEND_URL}equipementAtelier/update/${equi_id}/${compte_id}`, {
                    nomClientAtelier : nomClient ,
                    nomEquipementAtelier : nomEquipement,
                    reference: reference,
                    dateEntre: dateEntre,
                    operateurEntre: operateurEntre,
                    operateurReception: operateurReception,
                    commentaire : commentaire,
                    sortie: 1,
                    panne: panne,
                    etat: etat,
                    diagnostic: diagnostic
                  }).then((data) =>{
                  console.log(data);
                  request()
                  handleCloseDialogSortie();
                  })
                  .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  });
                }
  
      };


      const [searchQuery, setSearchQuery] = useState('');
      // Configuration de Fuse.js
  const fuseOptions = {
   keys: ['reference', 'nomEquipementAtelier', 'nomClientAtelier'], // Les champs à rechercher
   includeScore: true,
  };
  
  // Initialiser un nouvel objet Fuse avec les options
  const fuse = new Fuse(listEquipement, fuseOptions);
  
  // Fonction pour gérer le changement de la recherche
  const handleSearchChange = (e) => {
   const query = e.target.value;
   setSearchQuery(query);
  
   // Effectuer la recherche et mettre à jour les résultats
   const results = fuse.search(query);
  
   if (query==''){
     //setApproData(results.map((result) => result.item));
     request()
   }
   else{
     setListEquipement(results.map((result) => result.item));
   }
  
  };


    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listEquipement.map((row, index) => (
              `<tr>
              <td style="text-align: center;">${index + 1}</td>
              <td style="text-align: center;">${row.nomClientAtelier}</td>
              <td style="text-align: center;">${row.nomEquipementAtelier}</td>
              <td style="text-align: center;">${row.reference}</td>
              <td style="text-align: center;">${format(new Date(row.dateEntre), 'dd-MM-yyyy')}</td>
              <td style="text-align: center;">${row.operateurEntre}</td>
              <td style="text-align: center;">${row.operateurReception}</td>
              <td style="text-align: center;">${row.sortie == null || 0 ? 'Non' : 'Oui'}</td>
              <td style="text-align: center;">${row.sortie == null || 0 ? 'En cours' : 'Terminé'}</td>                                     
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des équipements en atelier</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                      color: red
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
                  <h2>Tableau des équipements en atelier</h2>
                  <table>
                    <thead>
                    <tr style="background-color: #F6F5F5;">
                    <th style="font-weight: 600; text-align: center;">Numéro</th>
                    <th style="font-weight: 600; text-align: center;">Client</th>
                    <th style="font-weight: 600; text-align: center;">Équipement</th>
                    <th style="font-weight: 600; text-align: center;">Référence</th>
                    <th style="font-weight: 600; text-align: center;">Date d'entrée</th>
                    <th style="font-weight: 600; text-align: center;">Opérateur entrée</th>
                    <th style="font-weight: 600; text-align: center;">Opérateur reception</th>
                    <th style="font-weight: 600; text-align: center;">Sortie</th>
                    <th style="font-weight: 600; text-align: center;">Statut</th>
                  </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const handleExportExcel = () => {
            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.json_to_sheet(listEquipement);
        
            //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
        
            // Mettre la première ligne en gras
            //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
        
            XLSX.utils.book_append_sheet(wb, ws , 'Equipements');
        
            XLSX.writeFile(wb , 'Equipements en atelier.xlsx');
        
          }


  const navigate = useNavigate();

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <div style={{marginRight: 'auto'}}></div>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #610C9F',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des équipements en ateliers
                  </Stack >
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15, 
                            marginRight:'auto',
                           marginLeft:8}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    <div></div>
                  <Stack alignItems='center'>
                    {windowWidth>900?
                    <TableContainer component={Paper}>
                            <Table sx={{  minWidth:windowWidth-200, }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#610C9F',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={10}>Équipements</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Client</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Équipement</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro Série</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date d'entrée</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Opérateur entrée</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Opérateur réception</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Sortie</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>                                                                                                                                                                             
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomClientAtelier}</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomEquipementAtelier}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.reference}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{format(new Date(row.dateEntre), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.operateurEntre}</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.operateurReception}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.sortie == null || 0 ? 'red' : 'green'}}>{row.sortie == null || 0 ? 'NON': 'OUI'}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.sortie == null || 0 ? 'red' : 'green'}}>{row.sortie == null || 0 ? 'EN COURS' : 'TERMINER'}</TableCell>
                                            
                                            <TableCell align="left" style={{fontWeight: 600}}>   
                                                  <Stack direction={'column'}
                                                      spacing={{sm: 1}}
                                                      alignItems={'left'}                                                   
                                                  >
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}} >
                                                      <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: row.sortie == 'NON' ? '#40A2E3' : 'grey' }} onClick={()=>{navigate(`/modification-approvisionement/${row.id}/${compte_id}`)}} >
                                                          <EditIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title= {'Marquer comme sortie'}>
                                                        <IconButton style={{color: row.sortie == null ? '#0D9276' : 'grey'}} disabled={row.sortie == null ? false : true} onClick={()=>{ 

                                                            setEqui_id(row.id )
                                                            //console.log('cdcdkmcm')
                                                            setCommentaire(row.commentaire)
                                                            setDateEntre(row.dateEntre)
                                                            setNomClient(row.nomClientAtelier)
                                                            setnomEquipement(row.nomEquipementAtelier )
                                                            setReference(row.reference )
                                                            setOperateurEntre(row.operateurEntre)
                                                            setOperateurReception(row.operateurReception)
                                                            handleClickOpenDialogSortie()

                                                            }} >
                                                              {<CheckCircleIcon />}
                                                          
                                                        </IconButton>
                                                    </Tooltip>                                                        
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-equipement-atelier/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                      </Tooltip>                                                                                                  
                                                      </Stack>
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}}>                                                                                                       
                                                      </Stack>                                                    
                                                  </Stack>                                             
                                                                                                     
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150, maxWidth:500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#610C9F',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Équipements</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Client</TableCell>                                                                                        
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}} colSpan={2}>{row.nomClientAtelier}</TableCell> 
                                    </TableRow> 
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Équipement</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro Série</TableCell>                                             
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.nomEquipementAtelier}</TableCell>
                                            <TableCell align="center" style={{}}>{row.reference}</TableCell>
                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date d'entrée</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Sortie</TableCell>                                            
                                    </TableRow> 
                                    <TableRow>
                                    <TableCell align="center" style={{}}>{format(new Date(row.dateEntre), 'dd-MM-yyyy')}</TableCell>
                                    <TableCell align="center" style={{ color: row.sortie == null || 0 ? 'red' : 'green'}}>{row.sortie == null || 0 ? 'NON': 'OUI'}</TableCell>
                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Opérateur entrée</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Opérateur réception</TableCell>                                                                                  
                                    </TableRow> 
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.operateurEntre}</TableCell>                                            
                                            <TableCell align="center" style={{}}>{row.operateurReception}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Statut</TableCell>                                          
                                    </TableRow>   
                                    <TableRow>
                                    <TableCell align="center" colSpan={2} style={{ color: row.sortie == null || 0 ? 'red' : 'green'}}>{row.sortie == null || 0 ? 'EN COURS' : 'TERMINER'}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>
                                    </TableRow>                                  
                                    <TableRow>                                            
                                            <TableCell align="center"  colSpan={2} style={{fontWeight: 600}}>   
                                                  
                                                      <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: row.sortie == 'NON' ? '#40A2E3' : 'grey' }} onClick={()=>{navigate(`/modification-approvisionement/${row.id}/${compte_id}`)}} >
                                                          <EditIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title= {'Marquer comme sortie'}>
                                                        <IconButton style={{color: row.sortie == null ? '#0D9276' : 'grey'}} disabled={row.sortie == null ? false : true} onClick={()=>{ 

                                                            setEqui_id(row.id )
                                                            //console.log('cdcdkmcm')
                                                            setCommentaire(row.commentaire)
                                                            setDateEntre(row.dateEntre)
                                                            setNomClient(row.nomClientAtelier)
                                                            setnomEquipement(row.nomEquipementAtelier )
                                                            setReference(row.reference )
                                                            setOperateurEntre(row.operateurEntre)
                                                            setOperateurReception(row.operateurReception)
                                                            handleClickOpenDialogSortie()

                                                            }} >
                                                              {<CheckCircleIcon />}
                                                          
                                                        </IconButton>
                                                    </Tooltip>                                                        
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-equipement-atelier/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                      </Tooltip>                                                                                                                     
                                            </TableCell>
                                                    </TableRow>                                                      
                                                </TableBody>
                                              ))}
                            </Table>
                    </TableContainer>
                    }
                    
                  </Stack>

                  <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total {listEquipement.length}</h5> 
                        </div>
                </div>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'équipement"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listProduit}
                              getOptionLabel={(option) => option.nomProd}
                              size='medium'
                              style={{minWidth: windowWidth>700?250:100}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={nomEquipement.value}
                              onChange={(event, newValue) => {
                                setnomEquipement(newValue ? newValue.nomProd:'');
                                console.log(newValue ? newValue.nomProd:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez l'équipement"  />
                              )}
                            />
                            <Autocomplete
                              id="combo-box-demo"
                              options={clientOptions}
                              getOptionLabel={(option) => option.nomClient}
                              size='medium'
                              style={{minWidth: windowWidth>700?250:100}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={nomClient.value}
                              onChange={(event, newValue) => {
                                setNomClient(newValue ? newValue.nomClient:'');
                                console.log(newValue ? newValue.nomClient:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un client"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listUtilisateurs}
                              getOptionLabel={(option) => option.name}
                              size='medium'
                              style={{minWidth:windowWidth>700?250:100}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={operateurEntre.value}
                              onChange={(event, newValue) => {
                                setOperateurEntre(newValue ? newValue.name: '');
                                console.log(newValue ? newValue.name:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez l'opérateur d'entrée"  />
                              )}
                            />
                            <Autocomplete
                              id="combo-box-demo"
                              options={listUtilisateurs}
                              getOptionLabel={(option) => option.name}
                              size='medium'
                              style={{minWidth:windowWidth>700?250:100}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={operateurReception.value}
                              onChange={(event, newValue) => {
                                setOperateurReception(newValue ? newValue.name:'');
                                console.log(newValue ? newValue.name:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez l'opérateur de reception"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Date d'entrée</label>
                                    <TextField type='date'                                         
                                        value={dateEntre}
                                        style={{minWidth: windowWidth>700?250:100}}
                                        onChange={(e)=> {
                                            setDateEntre(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>    
                            <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Numéro de série de l'équipement</label>                        
                                    <TextField type='text' 
                                        label='Numéro de série'
                                        value={reference}
                                        style={{minWidth:windowWidth>700?250:100}}
                                        onChange={(e)=> {
                                            setReference(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>
                          </Stack>                          
                            <TextField type='text' 
                                label='Commentaire'
                                value={commentaire}
                                multiline
                                minRows={3}
                                onChange={(e)=> {
                                    setCommentaire(e.target.value)
                                }}
                            ></TextField>                                                                                                        
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutEquipement()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog> 


                <Dialog
                  //fullScreen={fullScreen}
                  open={openDialogSortie}
                  onClose={handleCloseDialogSortie}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information sur l'équipement"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <Stack direction={windowWidth>700?'row':'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}>
                                <TextField type='text' 
                                    label="Panne"
                                    value={panne}
                                    style={{minWidth:windowWidth>700?230:100}}
                                    select
                                    onChange={(e)=> {
                                        setPanne(e.target.value)
                                    }}
                                >
                                        <MenuItem key={'Électrique'} value={'Électrique'}>
                                          {'Électrique'}
                                        </MenuItem>
                                        <MenuItem key={'Logiciel'} value={'Logiciel'}>
                                          {'Logiciel'}
                                        </MenuItem>
                                        <MenuItem key={'Mécanique'} value={'Mécanique'}>
                                          {'Mécanique'}
                                        </MenuItem>
                                        <MenuItem key={'Autres'} value={'Autres'}>
                                          {'Autres'}
                                        </MenuItem>
                                    </TextField> 
                                 <TextField type='text' 
                                    label="Etat de l'équipement"
                                    value={etat}
                                    style={{minWidth: windowWidth>700?230:100}}
                                    select
                                    onChange={(e)=> {
                                        setEtat(e.target.value)
                                    }}
                                >
                                    <MenuItem key={'Fonctionne'} value={'Fonctionne'}>
                                          {'Fonctionne'}
                                        </MenuItem>
                                        <MenuItem key={'Non-Fonctionnant'} value={'Non-Fonctionnant'}>
                                          {'Non-Fonctionnant'}
                                        </MenuItem>
                                </TextField>
                            </Stack>
                            <Stack>
                                <TextField type='text' 
                                    label="Diagnostic"
                                    value={diagnostic}
                                    style={{minWidth: windowWidth>700?230:100}}
                                    multiline={3}
                                    onChange={(e)=> {
                                        setDiagnostic(e.target.value)
                                    }}
                                ></TextField>
                            </Stack>
                                                                             
                            <Button variant="contained" endIcon={<CheckCircleIcon />} disabled={panne === null ? true : false} onClick={() => {modifierStatutEquipement()}} style={{fontWeight: 600}}>
                              Valider comme sortie 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogSortie} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
        </div>
    );
    
    function nextPage(){
      if(firstIndex+10 < listEquipement.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
}