import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress,MenuItem, Autocomplete,Box, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container, IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip } from '@mui/material';
import { Navigate, useNavigate,useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import * as XLSX from 'xlsx'
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Fuse from 'fuse.js';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
export default function Caisse() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [listOperationT, setListOperationT] = useState([]);
  const [listCompteT, setListCompteT] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [libelleTreso, setlibelleTreso] = useState('');
  const [typeOperation, settypeOperation] = useState('');
  const [natureOpe, setNatureOpe] = useState('');
  const [dateOpe, setDateOpe] = useState('');
  const [designaOpe, setDesignaOpe] = useState('');
  const [montantOpe, setMontantOpe] = useState('');
  let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 5;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listOperationT.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listOperationT.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700? 5:3;
    
    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

  const [openDialog, setOpenDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const { compte_id } = useParams();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  const request = async () => {
    try{
    await axios.get(`${BACKEND_URL}operationTresorerie/${compte_id}`)
      .then(({ data }) => {
        setListOperationT(data);
        console.log(listOperationT);
      }).catch((error) => {
        setBackendErrorMessage(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}tresorerie/${compte_id}`)
      .then(({ data }) => {
        setListCompteT(data);
        console.log(listCompteT);
      }).catch((error) => {
        setBackendErrorMessage(error.response.data.error);
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setBackendErrorMessage('')
    setOpenDialog(false);
  };
  
  const ajoutOperation = async (e) => {
    if(libelleTreso=='' || libelleTreso==null)
    {
      setBackendErrorMessage('Veuillez entrer le compte de trésorerie')
    }
    else if(typeOperation=='' || typeOperation==null)
    {
      setBackendErrorMessage('Veuillez entrer le type d\'opération')
    }
    else if(natureOpe=='' || natureOpe==null)
    {
      setBackendErrorMessage('Veuillez entrer la nature de l\'opération')
    }
    else if(dateOpe=='' || dateOpe==null)
    {
      setBackendErrorMessage('Veuillez entrer la date d\'opération')
    }
    else if(montantOpe=='' || montantOpe==null)
    {
      setBackendErrorMessage('Veuillez entrer le montant')
    }
    else if(designaOpe=='' || designaOpe==null)
    {
      setBackendErrorMessage('Veuillez entrer la désignation')
    }
    else {
    await axios.post(`${BACKEND_URL}operationTresorerie/create/${compte_id}`, {
                        libelleTreso : libelleTreso,
                        typeOperation: typeOperation,
                        nature: natureOpe,
                        dateOpeTreso: dateOpe,
                        montant: montantOpe,
                        designaOpe: designaOpe
              }).then((data) =>{
                request();
                console.log(data)
                setOpenDialog(false);
              })
              .catch(error => {
                console.error('Erreur de requête :', error);
                setBackendErrorMessage(error.response.data.error)
    });
  }
  };

  const Deleterequest = async (approId) => {
    if (!window.confirm('Voulez-vous vraiment supprimer?')) {
      return;
    }    
    try {
      await axios.delete(`${BACKEND_URL}operationTresorerie/destroy/${approId}/${compte_id}`);
      console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
      // Ajoutez ici la logique supplémentaire après la suppression
      setOpen(true);      
      request();
      window.alert('Element supprimer avec succès')
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'approvisionnement', error);
      window.alert(error.response.data.error)
      // Ajoutez ici la gestion des erreurs
    }
  }

  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
  };

  const [searchQuery, setSearchQuery] = useState('');
  // Configuration de Fuse.js
const fuseOptions = {
keys: ['libelleTreso', 'typeOperation', 'typeIntervention'], // Les champs à rechercher
includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listOperationT, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
const query = e.target.value;
setSearchQuery(query);

// Effectuer la recherche et mettre à jour les résultats
const results = fuse.search(query);

if (query==''){
 //setApproData(results.map((result) => result.item));
 request()
}
else{
 setListOperationT(results.map((result) => result.item));
}

};
  

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
        
          const tableRows = listOperationT.map((row, index) => (
            `<tr>
            <td align="center";">${index + 1}</td>
            <td align="center";">${row.libelleTreso}</td>
            <td align="center";">${row.typeOperation}</td>
            <td align="center";">${row.designaOpe==null ? 'RAS':row.designaOpe}</td>
            <td align="center";">${row.referenceOperation==null ? 'RAS':row.referenceOperation}</td>
            <td align="center";">${format(new Date(row.dateOpeTreso), 'dd-MM-yyyy')}</td>
            <td align="center";">${row.montant}</td>
            <td align="center";">${row.soldeApresOpert}</td>
            </tr>`
          )).join('');
          
          // Utilisez la chaîne HTML générée pour construire le document HTML
          const htmlContent = `
            <html lang="fr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Tableau des opérations de trésoreries</title>                
                <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h2>Tableau des opérations de trésoreries</h2>
                <table>
                  <thead>
                    <tr>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Numéro</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Compte trésorerie</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Type d'opération</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Désignation</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Référence</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Date</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Montant</th>
                        <th align="center" style="font-weight: 600; background-color: #F6F5F5;">Solde après</th>
                     </tr>
                  </thead>
                  <tbody>
                    ${tableRows}
                  </tbody>
                </table>
              </body>
            </html>`;
          
          // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();
  };

  useEffect(() => {
    try {
      request();
      
    } catch (error) {
      console.log(error)
    }
    const timer = setTimeout(() => {
      // Mettez à jour l'état pour arrêter le chargement après 3 secondes
      setLoading(false);
    }, 2000); // 3000 millisecondes = 3 secondes

    // Nettoyez le timer lorsque le composant est démonté
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
    }, 200);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timer);
      clearInterval(interval)
      window.removeEventListener('resize', handleResize);
  };

  }, []);

  const handleExportExcel = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(listOperationT);

    //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });

    // Mettre la première ligne en gras
    //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });

    XLSX.utils.book_append_sheet(wb, ws , 'Opérations');

    XLSX.writeFile(wb , 'Opérations de trésprerie.xlsx');

  }

  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if( !loading) {
  return (
    <div style={{ backgroundColor: '#fcfcfc', height: '100vh', width: '100%', }}>
      <Nav compte_id={compte_id}/>
      <Stack direction={'row'}
        alignItems='center'
        spacing={{}}
        sx={{ margin: 3 }}>
        <div style={{marginRight: 'auto'}}></div>
        <Tooltip title="Imprimer">
          <IconButton onClick={() => handlePrint()} >
            <PrintIcon style={{ color: 'blue' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en PDF">
          <IconButton onClick={() => handlePrint()} >
            <PictureAsPdfIcon style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en EXCEL">
          <IconButton onClick={() => handleExportExcel()} >
            <TableChartIcon style={{ color: 'green' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en CSV">
          <IconButton onClick={() => {}} >
            <DescriptionIcon style={{ color: '#2D9596' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copier">
          <IconButton onClick={() => {}} >
            <FileCopyIcon style={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction={'column'}
        alignItems='center'
        sx={{
          marginTop: 3,
          marginLeft: 2,
          marginRight: 2,
          borderRadius: 10,
          boxShadow: '0px 0px 5px #FF5200',
          backgroundColor: 'white',
          minWidth:windowWidth-150,
        }}>
        <Stack sx={{ fontWeight: 600, fontSize: 20, margin: 2 }}>
          Liste des opérations de trésorerie
        </Stack>
        
        <Stack>
        <Stack direction={windowWidth>700?'row':'column'}
          spacing={windowWidth>700?windowWidth/20:1}
        alignItems={windowWidth>700?'':'center'}
        >
        <div style={{marginBottom: 20,
           marginRight: windowWidth>700?'auto':'',
          }}>
                    <TextField type="text" 
                    style={{ minWidth: windowWidth>700?220:windowWidth-100}}
                          value={searchQuery} 
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
        </div>
        <div style={{marginBottom: 20}}>
        <Autocomplete
                              id="combo-box-demo"
                              options={listCompteT}
                              getOptionLabel={(option) => option.libelleTreso}
                              size='medium'
                              style={{minWidth: windowWidth>700?220:windowWidth-100}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={libelleTreso.value}
                              onChange={(event, newValue) => {
                                setlibelleTreso(newValue ? newValue.libelleTreso:'');
                                console.log(newValue ? newValue.libelleTreso:'');
                                let id_treso = newValue ? newValue.id:null;
                                if(id_treso!=null){
                                 axios.get(`${BACKEND_URL}operationTresorerie/filtrage/${id_treso}`)
                                            .then(({ data }) => {
                                              setListOperationT(data);
                                              console.log(listOperationT);
                                            }).catch((error) => {
                                              setBackendErrorMessage(error.response.data.error);
                                            });
                                          }
                              else{
                                            axios.get(`${BACKEND_URL}operationTresorerie/${compte_id}`)
                                            .then(({ data }) => {
                                              setListOperationT(data);
                                              console.log(listOperationT);
                                            }).catch((error) => {
                                              setBackendErrorMessage(error.response.data.error);
                                            });                                            
                                          }
                              }}
            
                            
                              renderInput={(params) => (
                                <TextField {...params} label="Filtrage"  />
                              )}
                            />
        </div>
        </Stack>
          {windowWidth>700?
          <TableContainer component={Paper}>
            <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
              <TableHead style={{ backgroundColor: '#FF5200', }}>
                <TableRow>
                  <TableCell align="center" style={{ color: 'white', fontWeight: 600, fontSize: 20 }} colSpan={8}>Opérations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>                  
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Compte trésorerie</TableCell>
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Type d'opération</TableCell>
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Désignation</TableCell>
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Référence</TableCell>
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Date</TableCell>
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Montant</TableCell>
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Solde après</TableCell>                  
                  <TableCell align="left" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Action</TableCell>

                </TableRow>
                {data.map((row, index) => (
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: 600 }}>{row.libelleTreso}</TableCell>
                    <TableCell align="left" style={{ fontWeight: 600 }}>{row.typeOperation}</TableCell>
                    <TableCell align="left" style={{ fontWeight: 600 }}>{row.designaOpe==null ? 'RAS':row.designaOpe}</TableCell>                    
                    <TableCell align="left" style={{ fontWeight: 600 }}>{row.referenceOperation==null ? 'RAS':row.referenceOperation}</TableCell>
                    <TableCell align="left" style={{ fontWeight: 600 }}>{format(new Date(row.dateOpeTreso), 'dd-MM-yyyy')}</TableCell>
                    <TableCell align="left" style={{ fontWeight: 600 }}>{row.montant.toLocaleString('fr-FR')}</TableCell> 
                    <TableCell align="left" style={{ fontWeight: 600 }}>{row.soldeApresOpert.toLocaleString('fr-FR')}</TableCell>                    
                    <TableCell align="left" style={{ fontWeight: 600 }}>                      
                      { /*<Tooltip title='Voir'>
                        <IconButton style={{ color: '#0D9276' }} onClick={() => { navigate(`/afficher-approvisionement/${row.id}`) }} >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title='Supprimer'>
                        <IconButton style={{ color: '#D24545' }} onClick={() => { Deleterequest(row.id) }} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <TableContainer sx={{ minWidth: 280, maxWidth:windowWidth<500?300:500 }} component={Paper}>
            <Table  aria-label="simple table">
              <TableHead style={{ backgroundColor: '#FF5200', }}>
                <TableRow>
                  <TableCell align="center" style={{ color: 'white', fontWeight: 600, fontSize: 20 }} colSpan={8}>Opérations</TableCell>
                </TableRow>
              </TableHead>
              {data.map((row, index) => (
              <TableBody>
                <TableRow>                  
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#FFEEA9' }}colSpan={2} >Compte trésorerie</TableCell>
                 </TableRow>
                 <TableRow>
                 <TableCell align="center" style={{ fontWeight: 600 }} colSpan={2} >{row.libelleTreso}</TableCell>                    
                 </TableRow>
                 <TableRow> 
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Type d'opération</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Désignation</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }}>{row.typeOperation}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.designaOpe==null ? 'RAS':row.designaOpe}</TableCell>                                        
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Référence</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Date</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }}>{row.referenceOperation==null ? 'RAS':row.referenceOperation}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{format(new Date(row.dateOpeTreso), 'dd-MM-yyyy')}</TableCell>                    
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Montant</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Solde après</TableCell>                  
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }}>{row.montant.toLocaleString('fr-FR')}</TableCell> 
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.soldeApresOpert.toLocaleString('fr-FR')}</TableCell>                    
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} colSpan={2} >Action</TableCell>
                </TableRow>                
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 600 }}colSpan={2}>                      
                      { /*<Tooltip title='Voir'>
                        <IconButton style={{ color: '#0D9276' }} onClick={() => { navigate(`/afficher-approvisionement/${row.id}`) }} >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title='Supprimer'>
                        <IconButton style={{ color: '#D24545' }} onClick={() => { Deleterequest(row.id) }} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                
              </TableBody>
              ))}
            </Table>
          </TableContainer>}
          <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total {listOperationT.length}</h5> 
                        </div>
                </div>
        </Stack>
        <Button variant="contained" style={{ margin: 20 }} startIcon={<AddIcon />} onClick={() => handleClickOpenDialog()} >
          Ajouter
        </Button>
      </Stack>
      <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'opération"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listCompteT}
                              getOptionLabel={(option) => option.libelleTreso}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={libelleTreso.value}
                              onChange={(event, newValue) => {
                                setlibelleTreso(newValue ? newValue.libelleTreso:'');
                                console.log(newValue ? newValue.libelleTreso:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un compte"  />
                              )}
                            />
                            <TextField type='text' 
                                label="Type d'operation"
                                value={typeOperation}
                                style={{minWidth:250}}
                                select
                                onChange={(e)=> {
                                  settypeOperation(e.target.value)
                                }}
                            >
                                <MenuItem key={'Encaissement'} value={'Encaissement'}>
                                {'Encaissement'}
                              </MenuItem>
                              <MenuItem key={'Décaissement'} value={'Décaissement'}>
                                {'Décaissement'}
                              </MenuItem>
                            </TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                             <TextField type='text' 
                                label="Nature de l'opération"
                                value={natureOpe}
                                style={{minWidth:250}}
                                select
                                onChange={(e)=> {
                                  setNatureOpe(e.target.value)
                                }}
                            >
                                <MenuItem key={'Espèce'} value={'Espèce'}>
                                {'Espèce'}
                              </MenuItem>
                              <MenuItem key={'Chèque'} value={'Chèque'}>
                                {'Chèque'}
                              </MenuItem>
                              <MenuItem key={'Carte'} value={'Carte'}>
                                {'Carte'}
                              </MenuItem>
                            </TextField>
                            <TextField type='number'  
                                label="Montant"                               
                                value={montantOpe}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                  setMontantOpe(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <TextField type='text'   
                                label="Désignation"                                
                                value={designaOpe}
                                style={{minWidth:420}}
                                onChange={(e)=> {
                                  setDesignaOpe(e.target.value)
                                }}
                            ></TextField>
                          <Stack direction={'column'}>
                              <label style={{fontWeight: 600}}>Date</label>
                            <TextField type='date'                                 
                                value={dateOpe}
                                style={{minWidth:420}}
                                onChange={(e)=> {
                                  setDateOpe(e.target.value)
                                }}
                            ></TextField>
                            </Stack> 
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutOperation()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
    </div>
  );

  function nextPage(){
    if(firstIndex+5 < listOperationT.length)
    {
      setCurrentPage(currentPage + 1);
    }
  }

  function prePage(){
    if(firstIndex-1>0)
    {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id){
    setCurrentPage(id);
  }
}
else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress
            />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}