import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {MenuItem, Select, CircularProgress,Autocomplete,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';



export default function DroitAcces() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listPermissions, setListPermissions] = useState([]);
    const [listAjoutPermisssions, setListAjoutPermissions] = useState([])
    const [role_permissions, setRole_permissions] = useState([]);
    const [role, setRole] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogCompte, setOpenDialogCompte] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { role_id } = useParams();
    const { compte_id } = useParams();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

      const ajoutPermission = async (e) => {
        if(listAjoutPermisssions==null|| listAjoutPermisssions=='')
        {
          setBackendErrorMessage('Veuillez sélectionner les permissions')
        }
        else 
        {
          await axios.post(`${BACKEND_URL}roles/${role.id}/permissions/${compte_id}`, {
                    permissions: listAjoutPermisssions,
                  }).catch(error => {
                    console.error('Erreur de requête :', error);
        });
        request();
        handleCloseDialog()
        setListAjoutPermissions('')

        }
        
      };


      const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setBackendErrorMessage('')
        
        setOpenDialog(false);
      };

      const handleSelectChange = (event) => {
        const {
          target: { value },
        } = event;

        const selectedValues = typeof value === 'string' ? value.split(',') : value;

        /*setListPiece(
         selectedValues
        );*/

        setListAjoutPermissions(selectedValues);

        console.log(selectedValues)
      
        console.log(listAjoutPermisssions)

        //console.log(listPiece)
      };



    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}permissions/${compte_id}`)
        .then(({data}) => {
            setListPermissions(data);
            console.log(listPermissions);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}roles/role_permissions/${compte_id}/${role_id}`)
        .then(({data}) => {
            setRole_permissions(data);
            console.log(role_permissions);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
        await axios.get(`${BACKEND_URL}roles/show/${role_id}/${compte_id}`)
        .then(({data}) =>{
          setRole(data);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const Deleterequest = async (permissionName) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}permissions/destroyPermi/${compte_id}/${role_id}/${permissionName}`);
          console.log(` ${permissionName} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          window.alert('Element supprimer avec succès')
          request();
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const chunkArray = (array, chunkSize) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    // Divisez la liste en groupes de 15
  const groupedPermissions = chunkArray(role_permissions, 4);

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          window.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 1000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
        
          window.addEventListener('resize', handleResize);


            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };

          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if( !loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationSysteme/${compte_id}/roles/${compte_id}`)}} >
                        RETOUR                      
                </Button>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth: windowWidth-150,
                      boxShadow: '0px 0px 5px #B67352',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des permissions du role {role.name}
                  </Stack>
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Permissions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {groupedPermissions.map((group, groupIndex) => (
                                  <TableRow key={groupIndex} alignItems='center'>
                                    {group.map((permission, index) => (
                                      <TableCell key={index} >
                                        <TableRow>
                                        <TableCell style={{ fontWeight: 600}}>
                                          {permission.name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(permission.name)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                          </TableRow>                                        
                                        
                                        
                                        
                                      </TableCell>                                      
                                    ))}
                                  </TableRow>
                                ))}
                                  </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Permissions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {groupedPermissions.map((group, groupIndex) => (
                                  <TableRow key={groupIndex} align='center'>
                                    {group.map((permission, index) => (
                                      <TableRow key={index} align='center'>
                                        <TableRow>
                                        <TableCell style={{ fontWeight: 600}}>
                                          {permission.name}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(permission.name)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                          </TableRow>                                                                                                                                                              
                                      </TableRow>                                      
                                    ))}
                                  </TableRow>
                                ))}
                                  </TableBody>
                            </Table>
                    </TableContainer>
  }
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {handleClickOpenDialog()}} >
                    Ajouter                      
            </Button> 
            </Stack>  
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information permissions"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <TableCell align="center" style={{fontWeight: 600}}>
                              <Select
                                  style={{ minWidth: windowWidth>600?400:windowWidth-75 }}
                                  multiple
                                  rows={4}
                                  value={listAjoutPermisssions || []}
                                  onChange={(e) => {
                                    handleSelectChange(e)
                                    //modifierPieces(index, list
                                  }
                                  }
                                  
                                >
                                  {listPermissions &&  listPermissions.map((row, index) => (
                                    <MenuItem key={index} value={row.name}>
                                      {row.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>                                        
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutPermission()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>        
        </div>

        
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}