import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import axios from 'axios';
import {CircularProgress, Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import { format } from 'date-fns';
import m2agroup from '../images/m2a.png';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';


export default function RapportIntervention() {


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);  
  const [listEquipementMaintenue, setListEquipementMaintenue] = useState([]);
    const [client, setClient] = useState([]);
    const [agence, setAgence] = useState([]);
    const [intervention, setIntervention] = useState([]);
    const [tckInterv, setTckInterv] = useState([]);
    const [open, setOpen] = React.useState(false);
    const { compte_id } = useParams();
    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listEquipementMaintenue.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listEquipementMaintenue.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = 5;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { interv_id, tck_id, agence_id } = useParams();

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const action = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );


    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const handlePrint = () => {
      const printWindow = window.open('ff', '_blank');

  // Générer le contenu du rapport d'intervention
  const htmlContent = `
    <html lang="fr">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Rapport d'intervention</title>                
        <style>
          body { font-family: Arial, sans-serif; }
          h5 { text-align: center; }
          span { margin-bottom: 8px; display: block; }
          table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          th, td { border: 1px solid #ddd; padding: 8px; text-align: center; }
          th { background-color: #f2f2f2; }
          .header {
            border-bottom: 2px solid #000;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }
        .header img {
            width: 200px;
            height: auto;
            float: left;
            margin-right: 20px;
        }
        .header h1,
        .header p {
            margin: 0;
            padding: 0;
        }
        /* Aligne les éléments pour l'impression */
        .header h1 {
            font-size: 24px;
            line-height: 1.2;
            color: red
        }
        .header p {
            font-size: 14px;
            line-height: 1.4;
        }
        </style>
      </head>
      <body>
      <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
        <h5>Rapport d'intervention</h5>
        <div style="display: flex; justify-content: space-between;">
          <div style="flex-grow: 1;">
            <span style="font-weight: 600;">Ticket : <span style="font-weight: 400;">${tckInterv.codeTck}</span></span>
            <span style="font-weight: 600;">Date : <span style="font-weight: 400;">${format(new Date(tckInterv.dateintervention), 'dd-MM-yyyy')}</span></span>
            <span style="font-weight: 600;">Technicien : <span style="font-weight: 400;">${intervention.name}</span></span>                                        
            <span style="font-weight: 600; margin-top: 20px;">Type d'intervention : <span style="font-weight: 400;">${tckInterv.typeIntervention}</span></span>
          </div>
          <div style="flex-grow: 1; text-align: right;">
            <span style="font-weight: 600;">Client : <span style="font-weight: 400;">${agence.nomClient}</span></span>
            <span style="font-weight: 600;"> Agence : <span style="font-weight: 400;">${agence.nomAgence}</span></span>
            <span style="font-weight: 600;">Téléphone : <span style="font-weight: 400;">${agence.telClient}</span></span>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th align="center" style=" font-weight: 600; font-size: 20px;" colspan="7">Equipements maintenus</th>
            </tr>
          </thead>
          <tbody>
              <tr>
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;" colSpan="2">Matériel</td>
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;" colSpan="2">État</td>                                                   
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Pièces changer</td> 
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Remarques</td>
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Commentaire</td>                                                                                                                         
            </tr>
            <tr>                                                  
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Type</td>
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">N° série</td>
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">État avant</td> 
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;">État après</td> 
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;"></td>                                                                                                                                       
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;"></td> 
              <td align="center" style="font-weight: 600; background-color: #F6F5F5;"></td> 
            </tr>
            ${listEquipementMaintenue.map((row, index) => (
              `<tr>                
                <td align="center" style="font-weight: 600;">${row.nomProd}</td>
                <td align="center" style="font-weight: 600;">${row.numeroSerie}</td>
                <td align="center" style="font-weight: 600; color: ${row.etatAvant === 'En service' ? 'green' : 'red'}">${row.etatAvant}</td>
                <td align="center" style="font-weight: 600; color: ${row.etatApres === 'En service' ? 'green' : 'red'}">${row.etatApres}</td>
                <td align="center" style="font-weight: 600;">${row.piecesChanger === 'null' ? 'Aucune' : row.piecesChanger}</td>                
                <td align="center" style="font-weight: 600;">${row.etatApres === '' ? '' : row.etatApres}</td>
                <td align="center" style="font-weight: 600;">${row.commentaire}</td>
              </tr>`
            )).join('')}
          </tbody>
        </table>

        <div>
          <div>
            <h5>Total équipements: ${listEquipementMaintenue.length}</h5>
          </div>
        </div>

        <div style="margin-top: 50px; display: flex; justify-content: space-between;">
          <div style="width: 45%;">
            <hr style="margin-bottom: 10px;">
            <p>Le technicien</p>
          </div>

          <div style="width: 45%;">
            <hr style="margin-bottom: 10px;">
            <p>Le client</p>
          </div>
        </div>
      </body>
    </html>`;

  // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
  printWindow.document.write(htmlContent);
  printWindow.document.close();
  printWindow.print();
    };

const request = async () => {
try{
  await axios.get(`${BACKEND_URL}equipement/equipementMaintenue/${interv_id}/${compte_id}`)
  .then(({data}) => {           
      setListEquipementMaintenue(data);
  }).catch((e) => {
    console.log(e)
  })

  await axios.get(`${BACKEND_URL}intervention/show/${interv_id}/${compte_id}`)
  .then(({data}) => {           
      setIntervention(data);
  }).catch((e) => {
    console.log(e)
  })

  

  await axios.get(`${BACKEND_URL}ticket_intervention/show/${tck_id}/${compte_id}`)
  .then(({data}) => {           
      setTckInterv(data);
  }).catch((e) => {
    console.log(e)
  })

  await axios.get(`${BACKEND_URL}agence/show/${agence_id}/${compte_id}`)
  .then(({data}) => {           
      setAgence(data);
  }).catch((e) => {
    console.log(e)
  })
} catch (error) {
  console.log(error)
}
}


    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 3000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 300);

        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
          window.removeEventListener('resize', handleResize);
      };
      }, []);
      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if( !loading) {
    return (
        <div style={{backgroundColor: '#fffcfc', paddingTop: 20, paddingLeft: 20, paddingBottom: 20}}>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>

            <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight: 'auto'}}   onClick={() => {handleLinkClick(`interventions/${compte_id}`)}} >
                    RETOUR                      
            </Button>
            <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
            </Tooltip> 
            <Tooltip title="Exporter en PDF">
                <IconButton onClick={() => {}} >
                    <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                </IconButton>
            </Tooltip> 
            <Tooltip title="Exporter en EXCEL">
                <IconButton onClick={() => {}} >
                    <TableChartIcon style={{color: 'green'}}/>                     
                </IconButton>
            </Tooltip> 
            <Tooltip title="Exporter en CSV">
                <IconButton onClick={() => {}} >
                    <DescriptionIcon style={{color: '#2D9596'}} />                     
                </IconButton>
            </Tooltip>
            <Tooltip title="Copier">
                <IconButton onClick={() => {}} >
                    <FileCopyIcon style={{color: 'black'}}/>                     
                </IconButton>
            </Tooltip>
            </Stack>
                  
                  
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5,
                      paddingTop: 5,
                      paddingBottom: 5,   
                      minWidth: windowWidth-150,                   
                      boxShadow: '0px 0px 5px #332941',
                      backgroundColor:'white'}}>                            
                            <Stack direction={'column'}
                                  //alignItems={'center'}
                                  >
                                    <Stack alignItems={'center'} marginBottom={3}>
                                    <h5>Rapport d'intervention</h5>
                                      </Stack>                                                               
                                  <Stack direction={'row'} //alignContent={'center'}
                                  marginBottom={3}>
                                      <Stack direction={'column'} style={{marginRight: 'auto'}}>
                                        <span style={{fontWeight: 600}}>Ticket : <span style={{fontWeight: 400}}>{` ${tckInterv.codeTck}`}</span></span>
                                        <span style={{fontWeight: 600}}>Date :  <span style={{fontWeight: 400}}>{`${format( Date(tckInterv.dateIntervention), 'dd-MM-yyyy')}`}</span></span>
                                        <span style={{fontWeight: 600}}>Technicien : <span style={{fontWeight: 400}}>{`${intervention.name}`}</span></span>                                        
                                        <span style={{fontWeight: 600, marginTop: 20}}>Type d'intervention : <span style={{fontWeight: 400}}>{`${tckInterv.typeIntervention}`}</span></span>
                                      </Stack>
                                      <Stack direction={'column'} spacing={0}>
                                        <span style={{fontWeight: 600}}> Client : <span style={{fontWeight: 400}}>{`${agence.nomClient}`}</span></span>
                                        <span style={{fontWeight: 600}}> Agence : <span style={{fontWeight: 400}}>{`${agence.nomAgence}`}</span></span>
                                        <span style={{fontWeight: 600}}>Téléphone : <span style={{fontWeight: 400}}>{`${agence.telClient}`}</span></span>
                                      </Stack>
                                  </Stack>
                              {windowWidth>700?
                                <TableContainer component={Paper} style={{marginBottom: 10}}>
                                    <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                      <TableHead style={{backgroundColor: '#332941',}}>
                                        <TableRow>
                                            <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Équipements maintenues</TableCell>
                                        </TableRow>                                        
                                      </TableHead>
                                      <TableBody>
                                      <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Matériel</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>État</TableCell>                                                   
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pièces changer</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Remarques</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>  
                                                                                                                                                                                        
                                          </TableRow>
                                          <TableRow>                                                  
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >N° Série</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État avant</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État après</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} ></TableCell>                                                                                                                                       
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} ></TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} ></TableCell> 
                                          </TableRow>
                                              {listEquipementMaintenue.map((row, index) => (
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600}}>{row.numeroSerie}</TableCell>                                                  
                                                  <TableCell align="center" style={{fontWeight: 600}}>{row.nomProd}</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600 , color: row.etatAvant=='En service' ? 'green' : 'red'}}>{row.etatAvant}</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600 , color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600}}>{row.piecesChanger == 'null' ? 'Aucune' : row.piecesChanger}</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600 , color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres=='En service' ? 'En service':'Hors service'}</TableCell>                                                         
                                                  <TableCell align="center" style={{fontWeight: 600}}>{row.commentaire}</TableCell>                                     
                                          </TableRow>
                                                      ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>  
                                :
                                <TableContainer component={Paper} style={{marginBottom: 10}}>
                                    <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                      <TableHead style={{backgroundColor: '#332941',}}>
                                        <TableRow>
                                            <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Équipements maintenues</TableCell>
                                        </TableRow>                                        
                                      </TableHead>
                                      {listEquipementMaintenue.map((row, index) => (
                                      <TableBody>
                                      <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#E3FEF7'}} colSpan={2}>Matériel</TableCell>                                                                                                                                                                                       
                                          </TableRow>
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >N° série</TableCell>                                                  
                                          </TableRow>
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600}}>{row.nomProd}</TableCell>                                                  
                                                  <TableCell align="center" style={{fontWeight: 600}}>{row.numeroSerie}</TableCell>                                                                                    
                                          </TableRow>
                                          <TableRow>
                                              <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>État</TableCell>                                                    
                                          </TableRow>
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État avant</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >État après</TableCell>                                                   
                                          </TableRow>
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600 , color: row.etatAvant=='En service' ? 'green' : 'red'}}>{row.etatAvant}</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600 , color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell>                                                   
                                          </TableRow>
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Pièces changer</TableCell> 
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Remarques</TableCell>
                                          </TableRow>
                                          <TableRow>
                                                 <TableCell align="center" style={{fontWeight: 600}}>{row.piecesChanger == 'null' ? 'Aucune' : row.piecesChanger}</TableCell>
                                                  <TableCell align="center" style={{fontWeight: 600 , color: row.etatApres=='En service' ? 'green' : 'red'}}>{row.etatApres=='En service' ? 'En service':'Hors service'}</TableCell>                                                                                                            
                                          </TableRow>
                                          <TableRow>
                                                  <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Commentaire</TableCell>  
                                                 
                                          </TableRow>
                                          <TableRow>                                                  
                                          <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.commentaire}</TableCell>  
                                          </TableRow>                                                                                        
                                          </TableBody>
                                                      ))}
                                    
                                    </Table>
                                </TableContainer>
                                }     
                            </Stack>                       
                            <div >                                    
                                    <div>
                                      <h5>Total équipements:  {listEquipementMaintenue.length}</h5> 
                                    </div>
                                </div>
                  </Stack>         
        </div>
    );

    function nextPage(){
      if( currentPage !== lastIndex)
      {
        setCurrentPage(currentPage + 1);
      }
    }

    function prePage(){
      if(currentPage !== firstIndex)
      {
        setCurrentPage(currentPage - 1);
      }
    }

    function changeCPage(id){
      setCurrentPage(id);
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}


