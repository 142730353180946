import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import axios from 'axios';
import {CircularProgress,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import * as XLSX from 'xlsx';
import Fuse from 'fuse.js';
import m2agroup from '../images/m2a.png';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
  var countData=1;

export default function Stocks() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [stockData, setStockData] = useState([]);
    const { compte_id } = useParams();

    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = stockData.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(stockData.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;    

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };
    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    };

    const handlePrint = () => {
      const printWindow = window.open('k', '_blank');
          
            const tableRows = stockData.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.nomProd}</td> 
                <td>${row.stock}</td> 
                <td>${row.libelleEntre}</td> 
                <td>${row.prixVente*row.stock}</td>                                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des stockages</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                      color: red
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
                  <h2>Tableau des stockages</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Produit</th>
                      <th>Quantité</th> 
                      <th>Entrepot</th>                                                                                                                                 
                      <th>Valeur</th>   
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
    };


    const handleExportExcel = () => {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(stockData);
  
      //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
  
      // Mettre la première ligne en gras
      //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
  
      XLSX.utils.book_append_sheet(wb, ws , 'Stockages');
  
      XLSX.writeFile(wb , 'Relevé des Stockages.xlsx');
  
    }

    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}stock/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setStockData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 2000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 200);

        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
          window.removeEventListener('resize', handleResize);
      };

      }, []);


      const [searchQuery, setSearchQuery] = useState('');
     // Configuration de Fuse.js
 const fuseOptions = {
  keys: ['nomProd', 'entrepot'], // Les champs à rechercher
  includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(stockData, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  // Effectuer la recherche et mettre à jour les résultats
  const results = fuse.search(query);

  if (query==''){
    //setApproData(results.map((result) => result.item));
    request()
  }
  else{
    setStockData(results.map((result) => result.item));
  }

};

      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if(!loading) {
    return (
      <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
      <Nav compte_id={compte_id} />
      <Stack direction={'row'}
              alignItems='center'
              spacing={{}}
               sx={{margin: 3}}>
          <div style={{marginRight: 'auto'}}>
                <h3>Gestion du stocks</h3>
          </div>
          <Tooltip title="Imprimer">
              <IconButton onClick={() => handlePrint()} >
                  <PrintIcon style={{color: 'blue'}}/>                    
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en PDF">
              <IconButton onClick={() => {}} >
                  <PictureAsPdfIcon  style={{color: 'red'}}/>                     
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en EXCEL">
              <IconButton onClick={() => handleExportExcel()} >
                  <TableChartIcon style={{color: 'green'}}/>                     
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en CSV">
              <IconButton onClick={() => {}} >
                  <DescriptionIcon style={{color: '#2D9596'}} />                     
              </IconButton>
          </Tooltip>
          <Tooltip title="Copier">
              <IconButton onClick={() => {}} >
                  <FileCopyIcon style={{color: 'black'}}/>                     
              </IconButton>
          </Tooltip>                 
      </Stack>                  
            <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 3,
                marginLeft: windowWidth>700?5:2, 
                marginRight: windowWidth>700?5:2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #2B2A4C',
                backgroundColor:'white'}}>   
            <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Liste des produits en stock
            </Stack>  
            <div style={{marginBottom: -10}}>
                <TextField type="text" 
                      value={searchQuery} 
                      onChange={handleSearchChange} 
                      placeholder="Rechercher...">
                </TextField>
                </div>                          
            <Stack alignItems='center'>            
            
                {windowWidth>700?
              <TableContainer component={Paper} sx={{marginTop: 5}}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Stockages</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>                                                                                                                                                     
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Produit</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Quantité</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Entrepot</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Valeur du stock</TableCell>                                                                                 
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.nomProd}</TableCell>     
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.stock}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.libelleEntre}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{(row.prixVente*row.stock).toLocaleString('fr-FR')} </TableCell>
                                      
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper} sx={{marginTop: 5}}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Stockages</TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#BAD1C2'}} >Produit</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#BAD1C2'}} >Quantité</TableCell> 
                              </TableRow>
                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600, color:'#5E1675'}}>{row.nomProd}</TableCell>     
                              <TableCell align="center" style={{fontWeight: 600}}>{row.stock}</TableCell>                                      
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Entrepot</TableCell>                                                                                                                                 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Valeur du stock</TableCell>                                                                                 
                              </TableRow>                                  
                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600}}>{row.libelleEntre}</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{(row.prixVente*row.stock).toLocaleString('fr-FR')} </TableCell>                              
                               </TableRow>                                                
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class="flex-row" style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total : {stockData.length}</h5> 
                  </div>
          </div>
            </Stack>            
            </Stack>                                      
          </div>
    );
   
    function nextPage(){
      if(firstIndex+10 < stockData.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
     
  }
}