import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import axios from 'axios';
import {CircularProgress, Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import Fuse from 'fuse.js';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
  var countData=1;

export default function Facture() {

    
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [factureData, setfactureData] = useState([]);
    const [listTck, setListTck] = useState([]);
    const [listClient, setListclient] = useState([]);
    const [listTypeFacture, setlistTypeFacture] = useState([]);
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [typeFacture, setTypeFacture] = useState('');
    const [tckFacture, setTckFacture] = useState('');
    const [clientFacture, setClientFacture] = useState('');
    const [objetFacture, setObjetFacture] = useState('');
    const [dateFacture, setDateFacture] = useState('');
    const [totalHTT, setTotalHTT] = useState('');
    const [nSticker, setNSticker] = useState('');
    const [nBonCommande, setNBonCommande] = useState('');
    const [taxe, setTaxe] = useState('');
    const [valeurTaxe, setValeurTaxe] = useState('');
    const { compte_id } = useParams();


    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = factureData.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(factureData.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    };

    const handlePrint = () => {
      const printWindow = window.open('', '_blank');
          
            const tableRows = factureData.map((row, index) => (
              `<tr>
                    <td align="center">${index+1}</td>
                    <td align="center">${row.refFacture}</td>
                    <td align="center">${row.objet}</td>
                    <td align="center">${format(new Date(row.dateFacture), 'dd-MM-yyyy')}</td>
                    <td align="center">${row.totalHTT}</td>
                    <td align="center">${row.nomTaxe == null || row.valeurTaxe == null ? 0 : `${row.nomTaxe} Valeur : ${row.valeurTaxe}`}</td>
                    <td align="center">${row.totalTTC}</td>                                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des factures</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                  </style>
                </head>
                <body>
                  <h2>Tableau des factures</h2>
                  <table>
                    <thead>
                      <tr>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Numéro</td>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Référence</td>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Objet</td>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Date facture</td>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Total HTT</td>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Taxe</td>
                        <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Total TTC</td>  
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
    };




    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}facture/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setfactureData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
        

        await axios.get(`${BACKEND_URL}config_facture/facture/${compte_id}`)
        .then(({data}) => {            
            setlistTypeFacture(data);
            console.log(listTypeFacture);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}client/${compte_id}`)
        .then(({data}) => {            
            setListclient(data);
            console.log(listClient);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}ticket_intervention/${compte_id}`)
        .then(({data}) => {            
            setListTck(data);
            console.log(listTck);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setBackendErrorMessage('')
        setOpenDialog(false);
      };
      
      const ajoutFacture = async (e) => {
        if(clientFacture=='')
        {
          setBackendErrorMessage('Veuillez entrer le client')
        }
        else if(objetFacture=='')
        {
          setBackendErrorMessage('Veuillez entrer l\'objet de la facture')
        }
        else if (typeFacture=='')
        {
          setBackendErrorMessage('Veuillez entre le type de facture')
        }
        else if (dateFacture=='')
        {
          setBackendErrorMessage('Veuillez entre la date de facture')
        }
        else if (totalHTT=='')
        {
          setBackendErrorMessage('Veuillez entre le montant total HTT')
        }
        else if (taxe=='')
        {
          setBackendErrorMessage('Veuillez entre la taxe ')
        }
        else {
        await axios.post(`${BACKEND_URL}facture/create/${compte_id}`, {
                        typeFacture: typeFacture,
                        objet: objetFacture,
                        dateFacture: dateFacture,
                        totalHTT: totalHTT,
                        nSticker: nSticker,
                        nBonCommande: nBonCommande,
                        nomTaxe : taxe,
                        valeurTaxe: valeurTaxe,
                        clientFacture: clientFacture,
                        tckFacture: tckFacture
                  }).then((data) =>{
                    request();
                    console.log(data)
                    setOpenDialog(false);
                  })
                  .catch(error => {
                    console.error('Erreur de requête :', error);
                    setBackendErrorMessage(error.response.data.error)
                    });  
                  }         
      };


      const Deleterequest = async (facture_id) => {
        if (!window.confirm('Voulez-vous vraiment supprimer?')) {
          return;
        }    
        try {
          await axios.delete(`${BACKEND_URL}facture/destroy/${facture_id}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${facture_id} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: ['tckFacture', 'clientFacture', 'refFacture'], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(factureData, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setfactureData(results.map((result) => result.item));
 }

};

const handleExportExcel = () => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(factureData);

  //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });

  // Mettre la première ligne en gras
  //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });

  XLSX.utils.book_append_sheet(wb, ws , 'Factures');

  XLSX.writeFile(wb , 'Relevé des Factures.xlsx');

}


    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 3000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 300);

        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(timer);
          clearInterval(interval)
          window.removeEventListener('resize', handleResize);
      };

      }, []);
      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if(!loading) {
    return (
      <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
      <Nav compte_id={compte_id} />
      <Stack direction={'row'}
              alignItems='center'
              spacing={{}}
               sx={{margin: 3}}>
          <div style={{marginRight: 'auto'}}><h3>Gestion des factures</h3></div>
          <Tooltip title="Imprimer">
              <IconButton onClick={() => handlePrint()} >
                  <PrintIcon style={{color: 'blue'}}/>                    
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en PDF">
              <IconButton onClick={() => handlePrint()} >
                  <PictureAsPdfIcon  style={{color: 'red'}}/>                     
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en EXCEL">
              <IconButton onClick={() => handleExportExcel()} >
                  <TableChartIcon style={{color: 'green'}}/>                     
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en CSV">
              <IconButton onClick={() => {}} >
                  <DescriptionIcon style={{color: '#2D9596'}} />                     
              </IconButton>
          </Tooltip>
          <Tooltip title="Copier">
              <IconButton onClick={() => {}} >
                  <FileCopyIcon style={{color: 'black'}}/>                     
              </IconButton>
          </Tooltip>                 
      </Stack>                  
            <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 3,
                marginLeft: 2, 
                marginRight: 2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #2B2A4C',
                backgroundColor:'white'}}>     
              <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Liste des factures
            </Stack>                         
            <Stack>
            <div style={{marginBottom: 20}}>
                    <TextField type="text" 
                          value={searchQuery} 
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    </div>
                    {windowWidth>700?
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Factures</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>                                                                                                                                                     
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ticket</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date facture</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Client</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Taxe</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Total TTC</TableCell>   
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                          
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.refFacture}</TableCell>     
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.tckFacture==null || row.tckFacture=='' ? row.objet : row.tckFacture}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{format(new Date(row.dateFacture), 'dd-MM-yyyy')}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.clientFacture} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{ row.nomTaxe==null || row.valeurTaxe==null ? 0 :`${row.nomTaxe} Valeur : ${row.valeurTaxe}`} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.totalTTC} </TableCell>                                      
                                      <TableCell align="left" style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                <Tooltip title='Modifier'>
                                                  <IconButton style={{ color: '#40A2E3' }} onClick={() => { /*navigate(`/modification-approvisionement/${row.id}`)*/ }} >
                                                    <EditIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Voir'>
                                                  <IconButton style={{ color: '#0D9276' }} onClick={() => { /*navigate(`/afficher-sortie/${row.id}`)*/ }} >
                                                    <ArrowForwardIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>                                                                                             
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Factures</TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}}>{row.refFacture}</TableCell>     
                                      
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ticket</TableCell> 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date facture</TableCell>                                                                                                                                 
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600}}>{row.tckFacture==null || row.tckFacture=='' ? row.objet : row.tckFacture}</TableCell>                                        
                                      <TableCell align="center" style={{fontWeight: 600}}>{format(new Date(row.dateFacture), 'dd-MM-yyyy')}</TableCell>
                                      
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Client</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Taxe</TableCell>
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.clientFacture} </TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{ row.nomTaxe==null || row.valeurTaxe==null ? 0 :`${row.nomTaxe} Valeur : ${row.valeurTaxe}`} </TableCell>                                      
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Total TTC</TableCell>   
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.totalTTC} </TableCell>                                                                            
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                          
                              </TableRow>                                  
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                <Tooltip title='Modifier'>
                                                  <IconButton style={{ color: '#40A2E3' }} onClick={() => { /*navigate(`/modification-approvisionement/${row.id}`)*/ }} >
                                                    <EditIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Voir'>
                                                  <IconButton style={{ color: '#0D9276' }} onClick={() => { /*navigate(`/afficher-sortie/${row.id}`)*/ }} >
                                                    <ArrowForwardIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>                                                                                             
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>
                                                
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total {factureData.length}</h5> 
                  </div>
          </div>
            </Stack>
            <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
              Ajouter                      
            </Button>
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de la facture"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listClient}
                              getOptionLabel={(option) => option.nomClient}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={clientFacture.value}
                              onChange={(event, newValue) => {
                                setClientFacture(newValue ? newValue.nomClient:'');
                                console.log(newValue ? newValue.nomClient:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un client"  />
                              )}
                            />
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTck}
                              getOptionLabel={(option) => option.codeTck}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={tckFacture.value}
                              onChange={(event, newValue) => {
                                setTckFacture(newValue ? newValue.codeTck:'');
                                console.log(newValue ? newValue.codeTck:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un ticket"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Objet de facture'
                                value={objetFacture}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                    setObjetFacture(e.target.value)
                                }}
                            ></TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeFacture}
                              getOptionLabel={(option) => option.typeFacture}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={typeFacture.value}
                              onChange={(event, newValue) => {
                                setTypeFacture(newValue ? newValue.typeFacture:'');
                                console.log(newValue ? newValue.typeFacture:'');
                                console.log(taxe) // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un type de facture"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Stack
                              direction={'column'}>
                                    <label style={{fontWeight: 600}}>Date de facture</label>
                                    <TextField type='date'
                                        value={dateFacture}
                                        style={{minWidth:250}}
                                        onChange={(e)=> {
                                            setDateFacture(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>
                            <Stack
                              direction={'column'}>
                                    <label style={{fontWeight: 600}}>Total HTT</label>
                                    <TextField type='number'
                                        value={totalHTT}
                                        style={{minWidth:250}}
                                        onChange={(e)=> {
                                            setTotalHTT(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >                            
                            <TextField type='text' 
                                label='Numéro sticker'
                                value={nSticker}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                    setNSticker(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Numéro bon commande'
                                value={nBonCommande}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                    setNBonCommande(e.target.value)
                                }}
                            ></TextField>                            
                          </Stack>
                          <TextField type='text' 
                                label='Taxe'
                                value={taxe}
                                style={{minWidth:230}}
                                select
                                onChange={(e)=> {
                                    setTaxe(e.target.value)                                    
                                }}
                            >
                                <MenuItem key={'TVA'} value={'TVA'}>
                                {'TVA'}
                              </MenuItem>                              
                            </TextField>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {
                                if (taxe =='TVA') {
                                  setValeurTaxe(18)
                                }                               
                                console.log(valeurTaxe);
                              ajoutFacture()
                              }} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>                                      
          </div>
    );
    
    function nextPage(){
      if(firstIndex+10 < factureData.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
     
  }
}