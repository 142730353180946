import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Box, CircularProgress,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import axios from 'axios';
import Fuse from 'fuse.js';
import * as XLSX from 'xlsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';


var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
var countData=1;

export default function AgenceOfClient() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listAgences, setListAgences] = useState([]);
    const [Client, setClient] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [nomAgence, setnomAgence] = useState('');
    const [villeAgence, setvilleAgence] = useState('');
    const [latitude, setlatitude] = useState('');
    const [longitude, setlongitude] = useState('');

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listAgences.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listAgences.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700? 5:3;
    
    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const { client_id } = useParams();
    const { compte_id } = useParams();


    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
try{
        await axios.get(`${BACKEND_URL}client/show/${client_id}/${compte_id}`)
        .then(({data}) => {
            setClient(data);
            console.log(Client);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}client/${client_id}/agences/${compte_id}`)
        .then(({data}) => {
            setListAgences(data);
            console.log(listAgences);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    
    const ajoutAgences = async (e) => {
      if(nomAgence=='')
      {
        setBackendErrorMessage('Veuillez entrez le nom de l\'agence')
      }
      else if(villeAgence=='')
      {
        setBackendErrorMessage('Veuillez entrez la ville de l\'agence')
      }
      else if(longitude=='')
        {
          setBackendErrorMessage('Veuillez entrez la longitude')
        }
      else if(latitude=='')
        {
          setBackendErrorMessage('Veuillez entrez la latitude')
        }        
      else{
                await axios.post(`${BACKEND_URL}agence/create/${client_id}/${compte_id}`, {
                  nomAgence: nomAgence,
                  villeAgence:villeAgence,
                  latitude:latitude,
                  longitude: longitude
            }).then((data) =>{
              request();
              console.log(data)
              setOpenDialog(false);
              setBackendErrorMessage('')
            })
            .catch(error => {
              console.error('Erreur de requête :', error);
              setBackendErrorMessage(error.response.data.error)
        });
      }
      

    };

    

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}agence/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);          
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };


    const handleExportExcel = () => {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(listAgences);
  
      //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
  
      // Mettre la première ligne en gras
      //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
  
      XLSX.utils.book_append_sheet(wb, ws , `Agences ${Client.codeClient}`);
  
      XLSX.writeFile(wb , `Agences ${Client.nomClient}.xlsx`);
  
    }

                
const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: ['nomAgence', 'villeAgence'], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listAgences, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setListAgences(results.map((result) => result.item));
 }

};

    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listAgences.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.nomAgence}</td> 
                <td>${row.villeAgence}</td>                                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des agences du client ${Client.nomClient}</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
                  <h2>Tableau des agences du client ${Client.nomClient}</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Nom</th>
                      <th>Ville</th>                      
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        const [loading, setLoading] = useState(true);


        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
          const timer = setTimeout(() => {
            // Mettez à jour l'état pour arrêter le chargement après 3 secondes
            setLoading(false);
          }, 2000); // 3000 millisecondes = 3 secondes
      
          // Nettoyez le timer lorsque le composant est démonté
         
            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              clearTimeout(timer);
              window.removeEventListener('resize', handleResize);
          };
          }, []);


  const navigate = useNavigate();

  if(!loading){
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`clients/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() =>{}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() =>{}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?10:2, 
                      marginRight: windowWidth>700?10:2, 
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #3E001F',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des agences de {Client.nomClient}
                  </Stack>
                  
                    <div></div>
                  <Stack>
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15 , 
                            marginRight: 'auto',
                            marginLeft:7}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    {windowWidth>700?                    
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth>990?800:600 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#3E001F',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Agences de {Client.nomClient}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ville</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomAgence}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.villeAgence}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                      spacing={{sm: 1}}
                                                      alignItems={'left'}                                                   
                                                  >                                                  
                                                    <Stack direction={'row'}
                                                       >                                                                                                                                                                 
                                                    <Tooltip title='Equipement'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/equipements/${compte_id}`)}} >
                                                          <DesktopWindowsIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                                            
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-agence/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                    
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                                       
                                                  </Stack> 
                                                  </Stack>                                            
                                                                                                     
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                    <Table sx={{minWidth: 150,maxWidth:500 }} aria-label="simple table">
                        <TableHead style={{backgroundColor: '#3E001F',}}>
                            <TableRow>
                                <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Agences de {Client.nomClient}</TableCell>
                            </TableRow>
                        </TableHead>
                        {data.map((row, index) => (
                        <TableBody>
                           <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5', fontSize: 16}} colSpan={2}>Agence</TableCell>
                                   
                            </TableRow>
                            <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ville</TableCell>                                    
                            </TableRow>
                            <TableRow>                            
                                    <TableCell align="center" style={{}}>{countData+index}-{row.nomAgence}</TableCell>
                                    <TableCell align="center" style={{}}>{row.villeAgence}</TableCell>                                    
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2} >Actions</TableCell>
                            </TableRow>
                                
                            <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>   
                                                                                                                                                                                                     
                                            <Tooltip title='Equipement'>
                                                <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/equipements/${compte_id}`)}} >
                                                  <DesktopWindowsIcon />
                                                </IconButton>
                                            </Tooltip>                                                                                                                                                            
                                            <Tooltip title='Voir'>
                                                <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-agence/${compte_id}`)}} >
                                                  <ArrowForwardIcon />
                                                </IconButton>
                                            </Tooltip>                                                    
                                            <Tooltip title='Supprimer'>
                                                <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                  <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>                                                                                                                                                       
                                                                                                                                                                               
                                    </TableCell>
                                            </TableRow>                                          
                                        </TableBody>
))}
                    </Table>
            </TableContainer>
                    }                    
                  </Stack>
                  <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Prev</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Next</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total {listAgences.length}</h5> 
                        </div>
                </div>

                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'agence"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >   
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}                       
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label="Nom de l'agence"
                                value={nomAgence}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                  setnomAgence(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Ville'
                                value={villeAgence}
                                onChange={(e)=> {
                                    setvilleAgence(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label="Longitude"
                                value={longitude}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                  setlongitude(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Latitude'
                                value={latitude}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setlatitude(e.target.value)
                                }}
                            ></TextField>
                          </Stack>                                                    
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutAgences()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>        
        </div>
    );

    function nextPage(){
      if(firstIndex+10 < listAgences.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }

  }

  else
  {
  return(
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // 100% de la hauteur de la vue
      }}
    >
      <Stack direction={'column'} alignItems={'center'} spacing={1}>
          <CircularProgress
          />
          <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
      </Stack>
    </Box>
  );
}
}