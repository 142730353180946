import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import { Autocomplete ,CircularProgress,MenuItem,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';



export default function ConfigurationCompteTresorerie() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listCompteT, setListCompteT] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [libelleTreso, setLibelleTreso] = useState([]);
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [soldeInit, setsoldeInit] = useState('');
    const [cheque, setCheque] = useState('');
    const [carte, setCarte] = useState('');
    const [espece, setEspece] = useState('');
    const [actif, setActif] = useState('');
    const { compte_id } = useParams();

    const [openDialog, setOpenDialog] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}tresorerie/${compte_id}`)
        .then(({data}) => {
            setListCompteT(data);
            console.log(listCompteT);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };

   
    const ajoutCompteT = async (e) => {
      if(libelleTreso==null || libelleTreso=='')
      {
        setBackendErrorMessage('Veuillez entrer le libellé du compte')
      }
      else if(adresse==null || adresse=='')
      {
        setBackendErrorMessage('Veuillez entrer l\'adresse du compte')
      }
      else if(telephone==null || telephone=='')
      {
        setBackendErrorMessage('Veuillez entrer le contact du compte')
      }
      else if(soldeInit==null || soldeInit=='')
      {
        setBackendErrorMessage('Veuillez entrer le solde initiale du compte')
      }
      else if(cheque==null || cheque=='')
      {
        setBackendErrorMessage('Veuillez choisir dans le libellé chèque')
      }
      else if(carte==null || carte=='')
      {
        setBackendErrorMessage('Veuillez choisir dans le libellé carte')
      }
      else if(espece==null || espece=='')
      {
        setBackendErrorMessage('Veuillez choisir dans le libellé espèce')
      }
      else if(actif==null || actif=='')
      {
        setBackendErrorMessage('Veuillez choisir dans le libellé actif')
      }
      else{
      await axios.post(`${BACKEND_URL}tresorerie/create/${compte_id}`, {
                      libelleTreso: libelleTreso,
                      adresse: adresse,
                      telephone:telephone,
                      soldeInit: soldeInit,
                      cheque: cheque,
                      carte: carte,
                      espece: espece,
                      actif: actif,
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };


   
    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}tresorerie/destroy/${approId}/${compte_id}`);
          console.log(`Compte tresorerie avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
        /*const printWindow = window.open('', '_blank');
        
        const tableRows = listCompteT.map((row, index) => (
          `<tr>
            <td>${index + 1}</td>
            <td>${row.name}</td>
            <td>${row.email}</td>
            <td>${row.tel}</td>
          </tr>`
        )).join('');
        
        // Utilisez la chaîne HTML générée pour construire le document HTML
        const htmlContent = `
          <html lang="fr">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Tableau des utilisateurs</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              <h2>Tableau des rôles</h2>
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Nom</th>
                    <th>E-mail</th>
                    <th>Téléphone</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableRows}
                </tbody>
              </table>
            </body>
          </html>`;
        
        // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();*/
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 1000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
        
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if( !loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationSysteme/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #B67352',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des comptes
                  </Stack>
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200  }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>Comptes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Compte trésor</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Adresse</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Solde initiale</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Chèque</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Carte</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Espèce</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {listCompteT.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{index+1}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.actif== 1 ? 'green' : 'red'}}>{row.libelleTreso}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.adresse}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.telephone}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.soldeInit}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.cheque== 1 ? 'OUI': 'NON'}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.carte== 1 ? 'OUI': 'NON'}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.espece== 1 ? 'OUI': 'NON'}</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: 'grey' }} disabled onClick={()=>{navigate(`/modification-approvisionement/${row.id}`)}} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Rendre inactif'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{
                                                          //navigate(`${row.id}/afficher-comptes`)
                                                          }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                                              
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200  }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#B67352',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>Comptes</TableCell>
                                    </TableRow>
                                </TableHead>
                                {listCompteT.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#FED8B1'}} colSpan={2}>Compte trésor</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600 , color: row.actif== 1 ? 'green' : 'red'}} colSpan={2}>{row.libelleTreso}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Adresse</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.adresse}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.telephone}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Solde initiale</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Chèque</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}}>{row.soldeInit}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.cheque== 1 ? 'OUI': 'NON'}</TableCell>
                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Carte</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Espèce</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}}>{row.carte== 1 ? 'OUI': 'NON'}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.espece== 1 ? 'OUI': 'NON'}</TableCell>                                            
                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Action</TableCell>                                            
                                    </TableRow>                                        
                                    <TableRow>
                                           <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: 'grey' }} disabled onClick={()=>{navigate(`/modification-approvisionement/${row.id}`)}} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Rendre inactif'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{
                                                          //navigate(`${row.id}/afficher-comptes`)
                                                          }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                                              
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                     
                                                </TableBody>
                                                 ))}
                            </Table>
                    </TableContainer>}
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du compte"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Nom compte trésorerie'
                                value={libelleTreso}
                                onChange={(e)=> {
                                    setLibelleTreso(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Adresse'
                                value={adresse}
                                onChange={(e)=> {
                                    setAdresse(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='tel' 
                                label='Téléphone'
                                value={telephone}
                                onChange={(e)=> {
                                    setTelephone(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='number' 
                                label='Solde initiale'
                                value={soldeInit}
                                onChange={(e)=> {
                                    setsoldeInit(e.target.value)
                                }}
                            ></TextField>
                            </Stack>
                            <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Chèque'
                                value={cheque}
                                select
                                style={{minWidth:210}}
                                onChange={(e)=> {
                                    setCheque(e.target.value)
                                }}
                            >
                              <MenuItem key={'OUI'} value={true}>
                                {'OUI'}
                              </MenuItem>
                              <MenuItem key={'NON'} value={false}>
                                {'NON'}
                              </MenuItem>
                            </TextField>
                            <TextField type='text' 
                                label='Carte'
                                value={carte}
                                select
                                style={{minWidth:210}}
                                onChange={(e)=> {
                                    setCarte(e.target.value)
                                }}
                            >
                              <MenuItem key={'OUI'} value={true}>
                                {'OUI'}
                              </MenuItem>
                              <MenuItem key={'NON'} value={false}>
                                {'NON'}
                              </MenuItem>
                            </TextField>
                            </Stack>
                            <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Espèce'
                                value={espece}
                                select
                                style={{minWidth:210}}
                                onChange={(e)=> {
                                    setEspece(e.target.value)
                                }}
                            >
                              <MenuItem key={'OUI'} value={true}>
                                {'OUI'}
                              </MenuItem>
                              <MenuItem key={'NON'} value={false}>
                                {'NON'}
                              </MenuItem>
                            </TextField>
                            <TextField type='text' 
                                label='Actif'
                                value={actif}
                                select
                                style={{minWidth:210}}
                                onChange={(e)=> {
                                    setActif(e.target.value)
                                }}
                            >
                              <MenuItem key={'OUI'} value={true}>
                                {'OUI'}
                              </MenuItem>
                              <MenuItem key={'NON'} value={false}>
                                {'NON'}
                              </MenuItem>
                            </TextField>
                            </Stack>
                          
                          
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutCompteT()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>   
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}