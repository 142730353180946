import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress ,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';
import B2 from '../images/B2.png';
import B from '../images/B.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
  B,
  T,
  techni,
  laptop,
  lock,
  hand,
  B2,     
  wallet
 // Ajoutez autant d'images que nécessaire
];

var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';

export default function ContratOfClient() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listContrat, setListContrat] = useState([]);
    const [Client, setClient] = useState([]);
    const [contrat, setContrat] = useState([]);
    const [contrat_id, setContrat_id] = useState([]);
    const [listTypeContrat, setListTypeContrat] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [referencesClient, setreferencesClient] = useState([]);
    const [statutContrat, setstatutContrat] = useState([]);
    const [regimeNormale, setRegimeNormale] = useState('');
    const [dateDebut, setdateDebut] = useState([]);
    const [dateFin, setdateFin] = useState([]);        
    const { compte_id } = useParams();     
    const [isLoading, setIsLoading] = useState(false);         

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 5;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listContrat.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listContrat.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700? 5:3;
    
    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const { client_id, client_name } = useParams();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogModifier, setOpenDialogModifier] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}client/show/${client_id}/${compte_id}`)
        .then(({data}) => {
            setClient(data);
            console.log(Client);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}typeContrat/${compte_id}`)
        .then(({data}) => {
            setListTypeContrat(data);
            console.log(listTypeContrat);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        axios.get(`${BACKEND_URL}client/${client_name}/contrat/${compte_id}`)
                .then(({data}) => {
                    setListContrat(data);
                    console.log(listContrat);
                }).catch ((error) =>
                {
                    setBackendErrorMessage(error.response.data.error);
                })
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };


    const handleClickOpenDialogModifier = () => {
      setOpenDialogModifier(true);
    };
  
    const handleCloseDialogModifier = () => {
      setBackendErrorMessage('')
      setOpenDialogModifier(false);
    };
    
    const ajoutContrat = async (e) => {
      if(contrat==''){
        setBackendErrorMessage('Veuillez remplir le type du contrat')
      }
      else if(statutContrat=='' )
      {
        setBackendErrorMessage('Veuillez remplir le statut du contrat')
      }
      else if( dateDebut=='' )
      {
        setBackendErrorMessage('Veuillez remplir la date de début du contrat')
      }
      else{
      await axios.post(`${BACKEND_URL}contrat/create/${compte_id}`, {
                      nomContrat:contrat,
                      nomClient: Client.nomClient,
                      referencesClient: Client.codeClient,
                      statutContrat: statutContrat,
                      fichierContrat: regimeNormale,
                      dateDebut: dateDebut,
                }).then((data) =>{
                  request();
                  console.log(data)

                  setOpenDialog(false);
                  setBackendErrorMessage('')
                  setIsLoading(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  setIsLoading(false);
      });

    }

    };

    const modifierContrat = async (e) => {
      console.log('cdmcdkkmcdkc')
      if(contrat==''){
        setBackendErrorMessage('Veuillez remplir le type du contrat')
      }
      else if(statutContrat=='' )
      {
        setBackendErrorMessage('Veuillez remplir le statut du contrat')
      }
      else{

      await axios.put(`${BACKEND_URL}contrat/update/${contrat_id}/${compte_id}`, {
                        nomContrat:contrat,
                        nomClient: Client.nomClient,
                        referencesClient: Client.codeClient,
                        statutContrat: statutContrat,
                        dateDebut: dateDebut,
                        dateFin: dateFin
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialogModifier(false);
                  setBackendErrorMessage('')
                  setIsLoading(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  setIsLoading(false);                  
      });
    }

    };

    

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}contrat/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

    const handleExportExcel = () => {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(listContrat);
  
      //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
  
      // Mettre la première ligne en gras
      //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
  
      XLSX.utils.book_append_sheet(wb, ws , `Contrats ${Client.codeClient}`);
  
      XLSX.writeFile(wb , `Contrats ${Client.nomClient}.xlsx`);
  
    }
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listContrat.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.nomContrat}</td> 
                <td>${row.nomClient}</td> 
                <td>${row.referencesClient}</td> 
                <td>${row.statutContrat}</td> 
                <td>${row.dateDebut}</td>
                <td>${row.dateFin}</td>                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des contrats du client ${Client.nomClient }</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                  </style>
                </head>
                <body>
                  <h2>Tableau des contrats du client ${Client.nomClient}</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Nom</th>
                      <th>Client</th>
                      <th>Référence Client</th>
                      <th>Statut</th>
                      <th>Date début</th>
                      <th>Date fin</th>
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        const [loading, setLoading] = useState(true);
        const [currentImage, setCurrentImage] = useState(0);

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }

            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 2000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 200);

            
            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);

            return()=>{
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
            };
          }, []);


  const navigate = useNavigate();


  if(!loading){
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`clients/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() =>{}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?10:2, 
                      marginRight: windowWidth>700?10:2, 
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #22A699',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des contrats de {Client.nomClient}
                  </Stack>
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 800 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#22A699',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Contrat de {Client.nomClient }</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Client</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence Client</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date début</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date fin</TableCell>                                                 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{index+1}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomContrat}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.referencesClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600 , color: row.statutContrat=='En cours' ? 'green' : 'red'}}>{row.statutContrat}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{format(new Date(row.dateDebut), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{format(new Date(row.dateFin), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                     
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}} >
                                                        <Tooltip title='Modifier le contrat'>
                                                        <IconButton style={{ color: '#40A2E3'}}  onClick={()=>{
                                                          setContrat_id(row.id)
                                                          setContrat(row.nomContrat)
                                                          setstatutContrat(row.statutContrat)
                                                          setdateDebut(row.dateDebut)
                                                          setdateFin(row.dateFin)
                                                          handleClickOpenDialogModifier()
                                                          }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                                            
                                                    <Tooltip title='Fichier du contrat'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{/*navigate(`/modification-approvisionement/${row.id}`)*/}} >
                                                          <InsertDriveFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                      </Stack>                                                                                                                                                                                                                                                      
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150, maxWidth:300 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#22A699',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Contrat de {Client.nomClient }</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Nom</TableCell>                                                                                        
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}} colSpan={2}>{index+1}-{row.nomContrat}</TableCell>
                                    </TableRow>
                                    <TableRow>                                    
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence Client</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>                                            
                                    </TableRow> 
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.referencesClient}</TableCell>
                                            <TableCell align="center" style={{  color: row.statutContrat=='En cours' ? 'green' : 'red'}}>{row.statutContrat}</TableCell>                                            
                                    </TableRow> 
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date début</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date fin</TableCell>                                                                                             
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{format(new Date(row.dateDebut), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="center" style={{}}>{format(new Date(row.dateFin), 'dd-MM-yyyy')}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Actions</TableCell>
                                    </TableRow>                                     
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={3}>                                                                                                         
                                                        <Tooltip title='Terminer le contrat'>
                                                        <IconButton style={{ color: '#40A2E3'}}  onClick={()=>{
                                                          setContrat_id(row.id)
                                                          setContrat(row.nomContrat)
                                                          setstatutContrat(row.statutContrat)
                                                          setdateDebut(row.dateDebut)
                                                          setdateFin(row.dateFin)
                                                          handleClickOpenDialogModifier()
                                                          }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                                            
                                                    <Tooltip title='Fichier du contrat'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{/*navigate(`/modification-approvisionement/${row.id}`)*/}} >
                                                          <InsertDriveFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                                                                                                                                                                                                                                                                           
                                            </TableCell>
                                                    </TableRow>
                                                      
                                                </TableBody>
                                                ))}
                            </Table>
                    </TableContainer>
                    }
                    <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total : {listContrat.length}</h5> 
                        </div>
                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {
                    setContrat('')
                    setstatutContrat('')
                    setRegimeNormale('')
                    setdateDebut('')
                    setdateFin('')
                    handleClickOpenDialog()}} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du contrat"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >      
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}                    
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeContrat}
                              getOptionLabel={(option) => option.nomTypeContrat}
                              size='medium'
                              style={{minWidth:200}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={contrat.value}
                              onChange={(event, newValue) => {
                                setContrat(newValue ? newValue.nomTypeContrat:'');
                                console.log(newValue ? newValue.nomTypeContrat:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un type"  />
                              )}
                            />
                            <TextField type='text' 
                                label='Statut contrat'
                                value={statutContrat}
                                select
                                style={{minWidth:200}}
                                onChange={(e)=> {
                                    setstatutContrat(e.target.value)
                                }}
                            >
                              <MenuItem key={'En cours'} value={'En cours'}>
                                {'En cours'}
                              </MenuItem>
                              <MenuItem key={'Terminer'} value={'Terminer'}>
                                {'Terminer'}
                              </MenuItem>
                            </TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >                            
                            <Stack direction={'column'}>
                              <label style={{fontWeight: 600}}>Date début</label>
                            <TextField type='date'                                 
                                value={dateDebut}
                                style={{minWidth:420}}
                                onChange={(e)=> {
                                  setdateDebut(e.target.value)
                                }}
                            ></TextField>
                            </Stack>                                                        
                          </Stack>
                          <Stack
                              direction={'column'}
                              spacing={{sm: 1}}
                              sx={{marginTop: 1}}
                          >
                            <label style={{fontWeight: 600}}>Fichier contrat</label>
                            <TextField type='file'
                                value={regimeNormale}
                                style={{minWidth:420}}
                                onChange={(e)=> {
                                    setRegimeNormale(e.target.value)
                                }}
                            ></TextField>                            
                          </Stack>                                                    
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutContrat()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogModifier}
                  onClose={handleCloseDialogModifier}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Modification du contrat"}
                  </DialogTitle>
                  <DialogContent>
                                             
                          <Stack
                              direction={'column'}
                              spacing={{sm: 1}}
                              sx={{marginTop: 1}}
                          >
                            {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <label style={{fontWeight: 600}}>Sélectionnez un type</label>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeContrat}
                              getOptionLabel={(option) => option.nomTypeContrat}
                              size='medium'
                              style={{minWidth:200}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={contrat.value}
                              onChange={(event, newValue) => {
                                setContrat(newValue ? newValue.nomTypeContrat: contrat);
                                console.log(newValue ? newValue.nomTypeContrat:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label={contrat}  />
                              )}
                            />
                            <label style={{fontWeight: 600}}>Statut contrat</label>
                            <TextField type='text' 
                                label='Statut contrat'
                                value={statutContrat}
                                select
                                style={{minWidth:200}}
                                onChange={(e)=> {
                                    setstatutContrat(e.target.value)
                                }}
                            >
                              <MenuItem key={'En cours'} value={'En cours'}>
                                {'En cours'}
                              </MenuItem>
                              <MenuItem key={'Suspendu'} value={'Suspendu'}>
                                {'Suspendu'}
                              </MenuItem>
                              <MenuItem key={'Terminer'} value={'Terminer'}>
                                {'Terminer'}
                              </MenuItem>
                            </TextField>

                          
                                                                             
                            <Button variant="contained" disabled={isLoading} endIcon={<CheckCircleIcon />} onClick={() => {
                              
                              setIsLoading(true);
                              modifierContrat()
                              }} style={{fontWeight: 600}}>
                              {isLoading?'Chargement': 'Valider'} 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogModifier} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>        
        </div>
    );
    function nextPage(){
      if(firstIndex+5 <listContrat.length)
      {
        setCurrentPage(currentPage + 1);
      }
    }

    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
      }
    }

    function changeCPage(id){
      setCurrentPage(id);
    }
}
else
{
return(
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // 100% de la hauteur de la vue
    }}
  >
    <Stack direction={'column'} alignItems={'center'} spacing={1}>
        <CircularProgress
        />
        <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
    </Stack>
  </Box>
);
}
}